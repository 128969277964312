module.exports = {
    title: "Social Platform",
    crud: {
        create: "Create Social Platform",
        edit: "Edit Social Platform",
        view: "View Social Platform",
    },
    table: {
        name: "Name",
        url: "Base URL",
    },
    form: {
        name: "Name",
        url: "Base URL",
    },
    validation: {
        required: {
            name: 'Name is required',
            url: 'Base URL is required',
        },
        minLength: {
            title: 'Name must have at least one',
            url: 'Base URL must have at least one',
        },
        common: {
            letter: 'letters.',
        }
    },
}