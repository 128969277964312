module.exports = {
    title: "Publisher",
    crud: {
        create: "Create Publisher",
        edit: "Edit Publisher",
    },
    table: {
        name: "Name",
        about: "About",
        mobile: "Mobile",
        address: "Address",
    },
    form: {
        name: "Name",
        about: "About",
        mobile: "Mobile",
        address: "Address",
    },
    validation: {
        required: {
            name: 'Name is required',
            mobile: 'Mobile is required',
        },
        minLength: {
            name: 'Name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
