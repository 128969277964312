module.exports = {
    title: "Job Openings",
    viewSchedule: 'View Interview Schedule',
    tab: {
        details: 'Details',
        media: 'Attachments',
        response: 'Response',
        report: 'Report',
        candidates: {
            tab: {
                candidatesList: 'Candidate List',
                outSystemCandidatesList: 'Other Candidate List',
                CandidateInterviewScheduleList: 'Interviewed List (Rounds)',
            }
        }
    },
    crud: {
        recruitments_status: 'status',
    },
    button: {
        view_interviews: 'View Interviews',
    },
    table: {
        title: "title",
        registeredType: "Registered As",
        firstName: 'User Name',
        description: 'Description',
        noOfOpenings: 'No of openings',
        minSalary: 'Min.Salary',
        maxSalary: 'Max. Salary',
        added: 'Added at',
        updated: 'Updated at',
        experience: 'Experience',
        commissionType: "Commission Type",
        commission: "Commission",
        company: 'Company',
        jobtype: 'Type',
        commissionMonth: "Commission Month(s)",
        candidates: {
            firstName: 'First Name',
            lastName:  'Last Name',
            nickName:  'Nick Name',
            email:    'Email',
            company:  'Company',
            interview: {
                template: 'Template Title',
                round: 'Round',
                status: 'Status',
                remarks: 'Remarks',
                mode: 'Interview Mode',
                interviewDate: 'On Date',
                interviewTime : 'On time',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            }
        },

    },
    detail: {
        title: "Title",
        status: "Status",
        registeredType: "Registered As",
        firstName: 'User Name',
        description: 'Description',
        noOfOpenings: 'No of openings',
        minSalary: 'Min.Salary',
        maxSalary: 'Max. Salary',
        added: 'Added at',
        updated: 'Updated At',
        experience: 'Experience',
        commissionType: "Commission Type",
        commission: "Commission",
        commissionMonth: "Commission Month(s)",
        expiredDate: 'Expired at',
        company: 'Company',
        jobtype: 'Type',
        viewerType: ' Viewer Type',
        workType: 'Work Type',
        requirement: 'Requirement',
    },
    filter: {
        companyList: 'Companies',
        experience: 'Experience',
        commissionType: "Commission Type",
        jobType: 'Type',
        title: 'Title',
        workType: 'Work Type',
        requirement: 'Requirement',
        currency: 'Currency',
    },
}
