module.exports = {
    title: "Transactions",
    crud: {
        view_transaction: "View Transaction",
    },
    table: {
        orderId: "orderId",
        purchase: "Purchase",
        amount: "Amount",
        createdAt: "Created At",
        razorpayPaymentId: "Razorpay Payment Id",
        verified: 'Verified',
        razorPayStatus: 'RazorPay Status',
        orderStatus: "Order Status",
        isFree: 'Free',
        attempts: 'Attempts',
        isFuture: 'Future Plan?',
        company: 'Name',
        type: 'Registered As',
    },
    view: {},
    form: {},
    filter: {},
    validation: {
        required: {},
        minLength: {},
        common: {}
    },
}
