module.exports = {
    title: "Group Category Type",
    crud: {
        create: "Create Group Category Type",
        edit: "Edit Group Category Type",
        view: "View Group Category Type",
    },
    table: {
        title: "Title",
        isActive: 'Active',
        getGroupTitleVerificationDoc: 'Get Group Title Verification Doc?',
    },
    form: {
        title: "Title",
        isActive: 'Active',
        getGroupTitleVerificationDoc: 'Get Group Title Verification Doc?',
    },
    validation: {
        required: {
            title: 'Title is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
