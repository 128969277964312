module.exports = {
    title: "College Department",
    crud: {
        create: "Create College Department",
        edit: "Edit College Department",
        view: "View College Department",
    },
    table: {
        name: "Name",
        isActive: 'Active',
    },
    form: {
        name: "Name",
        isActive: 'Active',
    },
    validation: {
        required: {
            name: 'Name is required',
        },
        minLength: {
            name: 'Name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}