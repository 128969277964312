module.exports = {
    title: "Posts",

    tab: {
        details: 'Details',
        media: 'Attachments',
        response: 'Response',
        report: 'Report',
    },
    crud: {
        create: "Create Post",
        edit: "Edit Post",
        view_response: "View Response",
        view_report: "View Report",
        view_media: 'View Attachments',
        post_status: "Post Status"
    },
    table: {
        name: "Name",
        visibility: "Visibility",
        userName: "User Name",
        description: "Description",
        postType: "Post type",
        budget: 'Budget',
        status: 'Status',
        information: 'Information',
        company: "Company",
        noOfPosition: "Positions",
        requiredFor: "Duration",
        required: "Required"
    },
    form: {
        name: "Name",
        branch: "Branch",
        visibility: "Visibility",
        individualshowToOther: "Show to Individual?",
        companyshowToOther: "Show to Company?",
        company: "Company",
        user: 'User',
        information: "Information",
        description: "Description",
        postType: "Post type",
        industry: 'Industry',
        subIndustry: 'Services',
        resourceType: 'Resource Type',
        currency: 'Currency',
        duration: ' For',
        priority: '',
        budget: 'Budget',
        minPrice: 'Minimum Price',
        maxPrice: 'Maximum Price',
        isActive: 'Active',
        workType: 'Work type',
        skills: 'Skills',
        tax: 'GST/Tax(%)',
        includedTax: 'GST/Tax included',
        isRemoteWork: 'Can this resource(s) work fromRemote Location?',
        remoteWorkAddress: 'Remote Location',
        status: "Status",
        noOfPosition: "Positions",
    },
    filter: {
        postType: "Post type",
        industry: 'Industry',
        subIndustry: 'Services',
        resourceType: 'Resource Type',
        currency: 'Currency  Type',
        duration: 'Duration',
        priority: 'Priority',
        budget: 'Budget',
        isActive: 'Active',
        workType: 'Work type',
        user: 'User',
        profileVerified: 'Profile Verified',
        remoteWork: 'Remote Work',
        skills: 'Skills',
        visibility: 'Visibility'
    },
    validation: {
        required: {
            description: 'Description is required',
            postType: 'Post type is required',
            industry: 'Industry is required',
            subIndustry: 'Services is required',
            budget: 'Budget is required',
            resourceType: 'Resource type is required',
            workType: 'Work type is required',
            priority: 'Priority  is required',
            currency: 'Currency type is required',
            duration: 'Duration is required',
            user: 'User is required',
            status: 'Status is required',
        },
        minLength: {
            name: 'Name must have at least',
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
