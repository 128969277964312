module.exports = {
    title: "Calendar",
    crud: {
        create: "Create Calendar",
        edit: "Edit Calendar",
        view: "View Calendar",
    },
    table: {
        name: "Name",
    },
    form: {
        date: "Schedule Date",
        registeredAs : "Registered As",
        name : "Name",
        staffPassword: "Staff Password",
        startDate: "Start Date",
        endDate: "End Date",
        time: "Time",
        startTime: "Start Time",
        endTime: "End Time",
        staff: "Staff",
        duration: "Duration",
        meetingLink: "Meeting Link",
        description: "Description",
        userId: "User",
        verifyAt: "Verify On",
        meetingStatus: "Meeting Status",
    },
    validation: {
        required: {
            startDate: 'Start date is required',
            endDate: 'End date is required',
            startTime: 'Start time is required',
            endTime: 'End time is required',
            staff: 'Staff is required',
            duration: "Duration is required",
        },
        minValue: {
            duration: "Min value is 10 mins.",
        },
        maxValue: {
            duration: "Max value is 60 mins.",
        },
    },
}
