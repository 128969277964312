module.exports = {
    title: "College Employer",
    crud:{
        view : 'Employer View',
    },
    table: {
        email: 'Email',
        company: 'Company',
        website: 'Website',
        address1: 'Address',
        firstName: "First Name",
        title: "Title",
        username:"Mobile Number"
    },
    form : {
        company:'Company',
        email:'Email',
        user:'User',
        college:'College',
        mobile_number:'Mobile Number',
        website:'Website',
        address1:'Address',
        state:'State',
        country:'Country',
        city:'City',
        isActive:'Active',
    }
}