module.exports = {
    title: "Image Cropper",
    button: {
        zoom_in: "Zoom In",
        zoom_out: "Zoom Out",
        move_left: "Move Left",
        move_right: "Move Right",
        move_up: "Move Up",
        move_down: "Move Down",
        flip_x: "Flip X",
        flip_y: "Flip Y",
        rotate_90: "Rotate +90deg",
        rotate_90deg: "Rotate -90deg",
        save: "Save",
        upload_image: "Upload Image",
        remove: "Remove",
        upload: "Upload",
        upload_profile: "Upload Image",
    },
    alert: {}
}
