module.exports = {
    title: "Candidates",
    tab: {
        details: 'Details',
        media: 'Attachments',
        response: 'Response',
        report: 'Report',
    },
    crud: {
        recruitments_status: 'status',
        edit: 'Update',
        view: 'Candidate Detail',
    },
    table: {
        title: "title",
        registeredType: "Registered As",
        firstName: 'User Name',
        description: 'Description',
        noOfOpenings: 'No of openings',
        minSalary: 'Min.Salary',
        maxSalary: 'Max. Salary',
        added: 'Added at',
        updated: 'Updated at',
        experience: 'Experience',
        commissionType: "Commission Type",
        commission: "Commission",
        company: 'Company',
        jobtype: 'Job Type',
        workType:'Work Type',
        commissionMonth: "Commission Month(s)",
        otherCandidates: {
            firstName: 'First Name',
            lastName: 'Last Name',
            currentCtc:'Current CTC',
            position : 'Position',
            availability: "Availability",
        },
    },
    detail: {
        industry: 'Industry',
        cFirstName: 'First Name',
        cLastName: 'Last Name',
        cNickName: 'Nick Name',
        cMobileNo: 'Mobile No',
        cEmail: 'Email',
        jobLocation: "Job Location",
        country: "Country",
        cities: "Citi(es)",
        title: "Title",
        status: "Status",
        registeredType: "Registered As",
        firstName: 'User Name',
        description: 'Description',
        noOfOpenings: 'No of openings',
        minSalary: 'Min.Salary',
        maxSalary: 'Max. Salary',
        added: 'Added at',
        updated: 'Updated At',
        experience: 'Experience',
        commissionType: "Commission Type",
        commission: "Commission",
        commissionMonth: "Commission Month(s)",
        expiredDate: 'Expired at',
        company: 'Company',
        jobtype: 'Job Type',
        viewerType: ' Viewer Type',
        workType: 'Work Type',
        requirement: 'Requirement',
        otherCandidates: {
            position : 'Position',
            email : 'Email',
            mobile : 'Mobile Number',
            currentCtc : 'Current C T C',
            qualification : 'Qualification',
            experience:'Experience',
            availability:'Availability',
            worktype:'Work Type',
            country: 'Country',
            city : 'City',
            skills: 'Skills',
            state : 'State',
        }
    },
    filter: {
        companyList: 'Companies',
        experience: 'Experience',
        commissionType: "Commission Type",
        jobType: 'Type',
        title: 'Title',
        workType: 'Work Type',
        requirement: 'Requirement',
        currency: 'Currency',
        otherCandidates : {
            availabilities : 'Availability',
            position: 'Position',
        },
    },
}
