module.exports = {
    title: "Interviewers",
    crud: {
        Interviewers: "Interviewers",
        view:'View Interviewer',
    },
    table: {
        'name':"Name",
        'email':"Email",
        'phone':'Phone Number',
        'department':'Department',
    },
    detail: {
        'name':"Name",
        'email':"Email",
        'phone':'Phone Number',
        'department':'Department',
        'description':'Description',
    },
}
