module.exports = {
    title: "My Profile",
    social_title: 'Social Profile Links',
    crud: {
        create: "Create Profile",
        edit: "Edit Profile",
        view_social: 'Social Links',
        social_edit: 'Update Links',
        verification: 'Verification',
    },
    table: {
        name: "Name",
        title: 'Title',
        company: 'Company',
        isVerified: "Verified",
        isActive: 'Active',
        platform: 'Platform',
        SocialLink: 'SocialLink',
    },

    form: {
        name: "Name",
        username: "Username",
        email: 'Email',
        address1: 'Location',
        country: 'Country',
        state: 'State',
        city: 'City',
        mobileNumber: "MobileNumber",
    },
    validation: {
        required: {
            name: 'Name is required',
            username: 'Username is required',
            email: 'Email is required',
            address1: 'Location is required',
            user: 'User is required',
            country: 'Country is required',
            state: 'State is required',
            city: 'City is required',
            plateform: 'platform is required',
            mobileNumber: 'Mobile number is required',
        },
        minLength: {
            name: 'Name must have at least',
            stateCode: 'State code must have at least',
            stateCodeName: 'State code name must have at least',
            mobileNumber: 'Mobile number must  enter 10 digits.',
        },
        email: {
            email: 'Enter valid email',
        },
        maxLength: {
            mobileNumber: 'Mobile number must  enter 10 digits.',
        },
        numeric: {
            mobileNumber: 'Mobile number must be digit.',
        },
        common: {
            letter: 'letters.',
        }
    },
}
