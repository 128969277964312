module.exports = {
    title: "Polls",
    crud: {
        create: "Create Category",
        edit: "Edit Category",
        post: "Post",
        poll_status: "Poll Status",
    },
    table: {
        name: "Name",
        type: 'Type',
        endDate: 'End Date',
        commentsCount: 'Total Comments',
        totalResponse: 'Total Responses',
        options: "Options",
        comments: {
            'name': 'Name',
            'comment': 'Comment',
            'created': 'Added At',
            'updated': 'Updated At',

        }
    },
    detail: {
        post: 'Give And Ask Categories Post',
        report: 'Give And Ask Categories Post Report',
    },
    form: {
        name: "Name",
        status: 'Active',
    },
    validation: {
        required: {
            name: 'Name is required.',
        }
    },
}
