module.exports = {
    title: "Unique Identification Names",
    crud: {
        create: "Create Unique Identification Name",
        edit: "Edit Unique Identification Name",
    },
    table: {
        maxlength: "Maxlength",
        name: "Name",
        regex: "Regex",
        countryName: "Country Name",
        isActive: 'Status',
        isServiceTax: 'isServiceTax'
    },
    form: {
        maxlength: "Maxlength",
        name: "Name",
        regex: "Regex",
        countryName: "Country Name",
        isActive: 'Status',
        isServiceTax: 'Service Tax'
    },
    validation: {
        required: {
            name: 'Name is required',
            countryName: 'Country name is required',

        },
        minLength: {
            name: 'Name must have at least',
            countryName: 'Country name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
