module.exports = {
    title: "Group Title Verification Request",
    crud: {
        detail: "Job Title (Designation) request detail",
    },
    table: {
        name: "Name",
        date: "Date",
        status: 'Status',
        uploadDocByAdmin: 'Upload Doc By Admin?',
        reason: 'Reason',
        created_at: "Created Date",
        updated_at: "Updated Date",
    },
    form: {
        name: "User Name",
        old_name: 'Old Name',
        requestedName : 'Requested  User Name',
        requestedTitle : 'Requested  Title',
        is_first_title_lbl : 'Title Name',
        currentTitle : 'Current  Title',
        isFirst:'New User',
        oldTitle : 'Old Title Name',
        title: " Group Title",
        created_at: "Created Date",
        updated_at: "Updated Date",
        link: "Link",
        status: 'Status',
        uploadDocByAdmin: 'Upload Doc By Admin?',
        reason: 'Reason',
    },
    validation: {
        required: {
            oldPassword: 'Old password is required.',
            newPassword: 'New password is required.',
            confirmPassword: 'Confirm password is required.',
        },
        sameAsPassword: {
            confirmPassword: 'Confirm password does not match with new password.',
        },
        minLength: {
            newPassword: 'Password must have at least  6 letters.',
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
