module.exports = {
    title: "Report Type",
    crud: {
        create: "Create Report Type",
        edit: "Edit Report Type",
    },
    table: {
        name: "Name",
        type: "Type",
    },
    form: {
        name: "Name",
        type: "Type",
    },
    validation: {
        required: {
            name: 'Name is required',
            type: 'Type is required',
        },
        minLength: {
            name: 'Name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
