module.exports = {
    title: "Sub Genre",
    crud: {
        create: "Create Sub Genre",
        edit: "Edit Sub Genre",
    },
    table: {
        name: "Name",
        genreName: "Genre Name",
    },
    form: {
        name: "Name",
        genreName: "Genre Name",
    },
    validation: {
        required: {
            name: 'Name is required',
            genreName: "Genre name is required",
        },
        minLength: {
            name: 'Name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
