module.exports = {
    title: "College Discipline",
    crud: {
        view: "View College Discipline",
    },
    table: {
        name: "Name",
        user: "User Name",
        status: "Status",
        year: "Year",
        seats: "Seats",
        no_of_semester: "No of Semester",
    },
    form: {
        name: "Name",
        user: "User Name",
        status: "Status",
        year: "Year",
        seats: "Seats",
        no_of_semester: "No of Semester",
    }
}