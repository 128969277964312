module.exports = {
    title: "Plans",
    crud: {
        create: "Create Plan",
        edit: "Edit Plan",
        view: "View Plan",
    },
    table: {
        industry: "Industry",
        isAutoAssign: 'Is Auto Assign',
        isfree: 'Is Free',
        name: "Name",
        type: "Type",
        description: "Description",
        durationType: "Duration Type",
        duration : 'Duration',
        noOfUsers: "No.Of Joined ",
        noOfBranch: "No.Of Branch ",
        freshHired:'Fresh Hired?',
    },
    form: {
        type: "Type",
        sequence: "Sequence",
        industry: "Industry",
        name: "Name",
        description: "Description",
        duration: "Duration",
        status: "Status",
        no_user: "No Users",
        no_branch: "No Branch",
        can_join: "Can Join National Group?",
        price: "Price",
        country: "Country",
        action: "Action",
        is_free: "Is Free?",
        is_auto_assign: "Is Auto Assign?",
        price_user: "Price Per User",
        no_of: "No Of Duration",
        featuredetail: "Features",
        informToUser:'What did you change?',
        freshHired:'Fresh Hired?',
    },
    validation: {
        required: {
            type: 'Type is required',
            name: 'Name is required',
            description: 'Description is required',
            duration: "Duration  is required",
            status: "Status  is required",
            no_user: "No. Users  is required",
            no_branch: "No. Branch  is required",
            can_join: "Can join national group is required",
            country: "Country is required",
            price: "Price is required",
            is_free: "Is free is required",
            no_of: "No of duration is required",
            no_of_min: "No of duration is not less then 0",
            no_of_max: "No of duration is not grether ",
            featuredetail: "Features is required",
            industryId: "Industry is required",
            informToUser:"Add, What did you change.?",
        }
    },
}
