module.exports = {
    title: "Student Project",
    crud:{
        view: 'View',
        detail: 'Detail',
        skill: 'Skill',
    },
    table: {
        projectName: "Project Name",
        description: "Description",
        duration: "Duration",
        yourRole: "Student Role",
    },
    form:{
        description : "Description",
        duration : "Duration",
        noMember : "No. Member",
        projectName : "Project Name",
        singleHandley : "Single Handley",
        yourRole : "Student Role",
        student : "Student",
    },
}
