module.exports = {
    title: "College Member",
    crud:{
        view : 'Member View',
    },
    table: {
        firstName: "Name",
        email: 'Email',
        role: 'Role',
        userStatus: 'User Status',
        countryCode: 'Code',
        username: 'User Name',
    },
    form : {
        name:'Name',
        firstName:'First Name',
        role:'Role',
        email:'Email',
        mobile_number:'Mobile Number',
        cityName:'City',
        stateName:'State',
        countryName:'Country',
        department:'Separtment',
        isAdmin:'Is Admin',
    }
}