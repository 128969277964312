module.exports = {
    title: "Cities",
    crud: {
        create: "Create City",
        edit: "Edit City",
    },
    table: {
        name: "City Name",
        countryName: "Country Name",
        stateName: "State Name",
        isActive: 'Active',
    },
    detail: {
        title: 'City View',
        currencyName: 'currencyName,'
    },
    form: {
        name: "City Name",
        countryName: "Country Name",
        stateName: "State Name",
        isActive: 'Active',
    },
    placeholder: {
        country: "Select Country Name",
        state: "Select State Name"

    },
    validation: {
        required: {
            countryName: 'Country name is required',
            stateName: 'State name is required',
            name: 'City name is required',
            cityCode: 'City code is required',
            cityCodeName: 'City code name  is required',
            currencySymbol: 'Currency symbol is required',
            currencyCode: 'Currency code is required',
            currencyName: 'Currency name is required',
        },
        minLength: {
            name: 'Name must have at least',
            cityCode: 'City code must have at least',
            cityCodeName: 'City code name must have at least',
            currencySymbol: 'Currency symbol must have at least',
            currencyCode: 'Currency code must have at least',
            currencyName: 'Currency name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
