module.exports = {
    title: "Inquiries",
    crud: {
        view: 'Inquiries View',
    },
    table: {
        name: 'User Name',
        email: 'Email',
        cityName: 'City',
        countryName: 'Country',
        budget: 'Budget',
        description: 'Description',
    },
    form: {
        name: 'User Name',
        email: 'Email',
        mobile: 'Mobile Number',
        cityName: 'City',
        countryName: 'Country',
        budget: 'Budget',
        description: 'Description',
        inquiryIndustry: 'Inquiries Industry',
        yourIndustry: 'Your Industry',
    },
}
