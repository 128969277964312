module.exports = {
    title: "Opportunity Attachments",
    crud: {
        create: "Create Attachment",
        edit: "Edit Attachment",
        view: "Opportunity Attachment",
        view_report: "View Report",
        view_media: 'View Attachment',
    },
    table: {
        url: "Url",
        mediaType: "Attachment Type",
    },
    label: {
        'no_content': 'Attachment not found',
    },

    validation: {
        required: {},
    },
}
