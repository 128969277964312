module.exports = {
    title: "Book Format",
    crud: {
        create: "Create Book Format",
        edit: "Edit Book Format",
    },
    table: {
        name: "Name",
        code: "Code",
    },
    form: {
        name: "Name",
        code: "Code",
    },
    validation: {
        required: {
            name: 'Name is required',
        },
        minLength: {
            name: 'Name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
