module.exports = {
    title: "Partner Agencies",
    crud: {
        create: "Create Partner Agency",
        edit: "Edit Partner Agency",
        view: "View Partner Agency",
    },
    table: {
        companyName: 'Partner Agency Name',
        email: 'Email',
        address: 'Address',
        cpName: 'Contact Person Name',
    },
    detail: {
        title: 'City View',
        currencyName: 'currencyName,'
    },
    form: {
        company_name: 'Partner Agency Name',
        email: 'Email',
        address: 'Address',
        cp_email: 'Contact Person Email',
        cp_name: 'Contact Person Name',
        contact_no: 'Contact No.',
        cp_contact_no: 'Contact Person Contact No.',
        start_date: 'Start Date',
        end_date: 'End Date',
    },
    placeholder: {
        country: "Select Country",
        state: "Select State"
    },
    validation: {
        required: {
            company_name: 'Please enter partner agency name.',
            email: 'Please enter email.',
            address: 'Please enter address.',
            contact_no: 'Please enter contact no.',
            cp_name: 'Please enter contact person name.',
            cp_email: 'Please enter contact person email.',
            cp_contact_no: 'Please enter contact person contact no.',
        }
    },
}
