module.exports = {
    title: "User Titles",
    crud: {
        create: "Create User Title",
        edit: "Edit User Title",
    },
    table: {
        title: "Title",
        category: "Category Name",
    },
    form: {
        title: "Title",
        category: "Category Name",
    },
    validation: {
        required: {
            title: 'Title is required',
            category: 'Category is required',
        },
        minLength: {
            title: 'Title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
