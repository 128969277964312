module.exports = {
    title: "College",
    crud: {
        view: "College",
        pendingCollege: "Pending College",
        detail: "Detail",
        stream: "Streams",
        programme: "Programmes",
        discipline: "Disciplines",
        student: "Students",
        member: "Staffs",
        branches: "Campus",
        profileUpdateRequest: "College Update Request",
        employer: "Company",
        verification: "Verification",
        plan: "Plan Detail",
        jobDesignationRequests: "Job Title (Designation) Request",
        meetingList: "Meeting List",
        groups: "My Community",
    },
    table: {
        name: "Name",
        address1: "Address",
        country: "Country",
        email: "Email",
    },
    form: {
        name: "Name",
        address1 : "Address",
        city:"City",
        country:"Country",
        state:"State",
        email:"Email",
        countryCode:"Country Code",
        latitude:"Latitude",
        longitude:"Longitude",
        logo:"Logo",
        website:"Website",
        user:"User",
        university:"University",
    }
}