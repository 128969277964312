module.exports = {
    title: "Groups",
    crud: {
        create: "Create Group",
        edit: "Edit Group",
        detail: "Group Post Detail",
    },
    table: {
        state: "State",
        industry: "Industry",
        status: 'Status',
        group: 'Group Name'
    },
    form: {
        country: "Country",
        state: "State",
        industry: "Industry",
        subIndustry: 'SubIndustry',
        status: 'Status',
    },
    validation: {
        required: {
            country: 'Country is required.',
            state: 'State is required.',
            industry: 'Industry is required.',
            subIndustry: 'SubIndustry is required.'
        },
        common: {
            letter: 'letters.',
        }
    },
}
