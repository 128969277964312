module.exports = {
    title: "College Programme",
    crud: {
        view: "View College Programme",
    },
    table: {
        name: "Name",
        user: "User Name",
        status: "Status",
    },
    form: {
        name: "Name",
        user: "User Name",
        status: "Status",
    }
}