module.exports = {
    'form': {
        'user': 'User',
        'userName': 'User Name',
        'title': 'Title',
        'program': 'Select Program',
        'today': 'Today Task',
        'welcome': 'Welcome',
        'tomorrow': 'Tomorrow Task',
        'next_seven_days': 'Next Seven Days Task',
        'term': 'Select Term',
        'start_time': 'Start Time',
        'end_time': 'End Time',
        'image_format': 'Allowed extensions - jpg,jpeg,png',
        'select': '--Select--',
        'select_project': '--Select Opportunity--',
        'company': 'Company',
        'branch': 'Branch',
        'pick_from_map': 'Pick From Map',
        'allowed_extension': 'Allowed Extensions',
        'watermark_preview': 'Watermark Preview',
        'select_location': 'Select Location',
        'select_priority': 'Select Priority',
        'select_status': 'Select Status',
        'reason': 'Reason',
        'all': 'All',
        'menu': 'Menu',
        'csv': 'CSV',
        'pdf': 'PDF',
        'print': 'Print',
        'yes': 'Yes',
        'no': 'No',
        'of': 'Of',
        'status': 'Status',
        'month': 'Month',
        'year': 'Year',
        'employee': 'Employee',
        'amount': 'Amount',
        'total_amount': 'Total Amount',
        'date': 'Date',
        'opportunity': 'Opportunity Name',
        'task': 'Task Name',
        'other': 'Other',
        'ref_detail': 'Reference Details',
        'payment_mode': 'Payment Mode',
        'vendor_name': 'Vendor Name',
        'gst_no': 'G.S.T Number',
        'salary_detail': 'Salary Details',
        'source': 'Source',
        'payment_status': 'Payment Status',
        'mark_paid': 'Mark as Paid',
        'description': 'Description',
        'success': 'Success',
        'start_date': 'From Date',
        'end_date': 'To Date',
        'switch': 'Switch',
        'add_new': ' Add New',
        'days': 'Day(s)',
        'loading': 'Loading...',
        'created_at': 'Created At',
        'updated_at': 'Updated At',
        'created_by': 'Created By',
        'updated_by': 'Updated By',
        'download': 'Download',
        'isActive': 'Active',
        'isDefault': 'Move To Top',
    },

    'table': {
        'userName': 'User Name',
        'isActive': 'Active',
        'title': 'Title',
        'createdAt': 'Created At',
        'updatedAt': 'Updated At',
    },
    'tabs': {
        'user-page': {
            'profile': 'Profile',
            'projects': 'Opportunities',
        }
    },

    'label': {
        'mylogs': 'My Logs',
        'switch': 'Switch Company',
        'view_notification': 'View all Notifications',
        'admin': 'Admin',
        'not_found': 'No data found',
        'see_more': 'See More',
        'notification_text': 'Notifications',
        'clear_all': 'Clear All',
        'apply_leave': 'Apply For Leave',
        'gettingText': 'Getting Address...',
        'userAddress': 'Address', //show map headering
        'loadmore': 'Load More',
        'company': 'Company',
        'individual': 'Individual',
        'created_by': 'Created By',
        'updated_by': 'Updated By',
        'cost': 'Cost',
        'priority': 'Priority',
        'progress': 'Progress',
        'my_progress': 'My Task Progress',
        'assigned_users': 'Assigned users',
        'data_not_found': 'Data not found',
        'data_not_available': 'Data not available',
        'mark_inprogress': 'Mark InProgress',
        'mark_completed': 'Mark Completed',
        'project_hours': 'Opportunity Task Hours',
        'total_hours': 'Total Hours',
        'my_hours': 'My Task Hours',
        'attachments': 'Attachments'

    },
    'todo': {
        'tasks': 'Tasks',
        'add_a_task': 'Add a Task',
        'completed': 'Completed',
        'fresh_start': 'A fresh start',
        'anything_todo': 'Anything to add?',
    },
    filter: {
        button: {
            filter: 'Filter',
            cancel: 'Cancel',
        },
        companyIndividualUser: 'Company/Individual',
    },
    'js': {
        'location_determine': 'Cannot determine address at this location.',
        'delete_picture': 'Are you sure you want to delete the profile picture?',
        'cancel_leaves': 'Are you sure you want to Cancel the selected leaves?',
        'select_anyleaves': 'Please select any leave to cancel.',
        'sure_delete': 'Are you sure you want to delete?',
        'swal_delete': 'Are you sure you want to delete this item?',
        'cancel': 'Cancel',
        'swalOk': 'Ok',
        'swalYes': 'Yes',
        'yesDelete': 'Yes, delete',
        'yesCancel': 'Yes, Cancel',
        'swalWarning': 'Warning',
        'delete_only': 'Delete this only',
        'delete_follow': 'Delete this & following',
    },

    'highChart': {
        'printchart': 'Print Chart',
        'download_png': 'Download PNG image',
        'download_jpeg': 'Download JPEG image',
        'download_pdf': 'Download PDF document',
        'download_svg': 'Download SVG vector image',
        'download_csv': 'Download CSV',
        'download_xls': 'Download XLS',
        'projects': 'Opportunities',
        'milestones': 'Milestones',
        'tasks': 'Tasks',
        'count': 'Count',
    },
    'import': {
        'select_file': 'Select File to Import ',
        'import_file': 'Import File',
        'instructions': 'Please read following instructions carefully before import :',
        'extensions': 'These are the allowed file extensions : .xls,.xlsx & .csv.',
        'sample_file': 'Please download sample file from here.',
        'sample_download': 'Download Sample File.',
        'column': 'The first row in your file must contain column headers as given in above sample file.',
        'mandatory': 'These fields are mandatory to import',
        'details': 'details:',
    },
    'mapping': {
        'map': 'Please map the',
        'column': 'variables to your file column.',
        'variables': 'Variables',
        'file_column': 'Your File Column',
        'file_values': 'Your File Value',
        'accepted': 'Accepted Item List',
        'nodata': 'No Data Found',
        'reject': 'Rejected Item List',
        'goback': 'Go Back',
    },
    'validation': {
        'required': {
            'money': 'Please enter valid amount.',
            'email': 'Please enter valid email address.'
        },
        'accept': {
            'name': 'Please enter only alphabets.',
        },

    },
    'actions': {
        'status': 'Status',
        'delete': 'Delete',
        'quantity': 'Quantity'
    },
    general: {
        'reason': "Add Reason",
        'status': "Status",
        'export': 'Export',
        'back_to_list': 'Back to list',
        'submit': 'Submit',
        'update': 'Update',
        'actions': 'Actions',
        'active': 'Active',
        'inactive': 'Inactive',
        'cancel': 'Cancel',
        'yes': 'Yes',
        'no': 'No',
        'select_all': 'Select All',
        'expand_all': 'Expand All',
        'collapse_all': 'Collapse All',
        'toggle_navigation': 'Toggle Navigation',
        'copyright': 'Copyright',
        'load_more': 'Load More',
        'duplicate': 'Duplicate item & packing type',
        'edit_permission': 'Edit Permission',
    },
    swal: {
        title: 'Are you sure?',
        text: 'You want to override the record!',
        button: 'You will update the record!',
    },
    status: {
        want_to_block: 'Are you sure?',
        yes_block: 'Yes!',
        cancel: 'Cancel'
    },
    delete_swal: {
        are_you: 'Are you sure?',
        yes_delete: 'Yes, delete!',
        cancel: 'Cancel'
    },
    identity_verification_swal: {
        are_you: 'Are you sure?',
        yes_delete: 'Yes',
        cancel: 'Cancel'
    },
    deactive_swal: {
        are_you: 'Are you sure?',
        yes_delete: 'Yes!',
        cancel: 'Cancel'
    },
    'otp': {
        'otp': 'Invalid OTP',
    },
    'alert': {
        'error': 'Something has been wrong',
    },
}
