module.exports = {
    title: "Resource Type",
    crud: {
        create: "Create Resource Type",
        edit: "Edit Resource Type",
        view: "View Resource Type",
    },
    table: {
        title: "Title",
    },
    form: {
        title: "Title",

    },
    validation: {
        required: {
            title: 'Title is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
