module.exports = {
    title: "Currency Type",
    crud: {
        create: "Create Currency Type",
        edit: "Edit Currency Type",
        view: "View Currency Type",
    },
    table: {
        title: "Title",
        symbol: "Symbol",
    },
    form: {
        title: "Title",
        symbol: "Symbol",
    },
    validation: {
        required: {
            title: 'Title is required',
            symbol: 'Symbol is required',
        },
        minLength: {
            title: 'Title must have at least one',
            symbol: 'Symbol must have at least one',
        },
        common: {
            letter: 'letters.',
        }
    },
}
