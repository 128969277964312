module.exports = {
    title: "Templates",
    crud: {
        templates: "Templates",
        view: "View Rounds"
    },
    table: {
        name: "Name",
        status: 'Is Active',
        department: 'Department',
        rounds: 'Number Of Rounds',
        createdAt: 'Create At',
        updatedAt: 'Updated At',
        roundList: {
            title: "Title",
            template: "Template",
            createdAt: 'Create At',
            updatedAt: 'Updated At',
        }
    },
    detail: {
        name: "Name",
        status: 'Is Active',
        department: 'Department',
        rounds: 'Number Of Rounds',
        createdAt: 'Create At',
        updatedAt: 'Updated At',
        roundView: {
            title: "Title",
            description: "Description",
            template: "Template",
            createdAt: 'Create At',
            updatedAt: 'Updated At',
        }
    },
}
