<template>
  <div>
    <img :src="image" class="c-avatar" alt="" v-show="image" v-if="image !== ''"/>
    <img :src="defaultImage" class="c-avatar" v-show="defaultImage" alt="" v-else/>
    <span style="width:100%" v-c-tooltip="content" v-show="content">
      {{ content ? trimfunction(getname(content), length) : '' }}
    </span>

    <span v-if="!isProfileListPage" style="padding-left:40px;display:block" class="text-muted" v-show="content">
      {{ content ? companyName(content) : '' }}
    </span>

  </div>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "AvatarItem",
  mixins: [Mixin],
  components: {},
  props: ["image", "defaultImage", "content", "length", "type", "isProfileListPage", "allowCompany"],
  data() {
    return {
      largeModal: false,
      self: this,
    };
  },
  methods: {
    getname(row) {
      if (row !== 'undefined') {
        let result = (row !== null) ? row.split('-') : '';
        if (result)
          return result[0];
      } else {
        return '';
      }
    },
    companyName(row) {
      let result = (row !== null) ? row.split('-') : '';
      if (result)
        return result[1];
    }
  }
};
</script>
