<template>
  <div
    class="preloader"
    style="
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 100001;
      backface-visibility: hidden;
      background: #ffffff;
    "
  >
    <div
      class="loader_img"
      style="
        width: 50px;
        height: 50px;
        position: absolute;
        left: 50%;
        top: 50%;
        background-position: center;
        margin: -25px 0 0 -25px;
      "
    >
      <img :src="preLoaderImage" alt="loading..." height="64" width="64" />
    </div>
  </div>
</template>
<script>
import { Mixin } from "../../mixins";
export default {
  name: "preLoading",
  mixins: [Mixin],
  data() {
    return {
      preLoaderImage: "",
    };
  },
  mounted() {
    //   store.commit('showLoader', false); // Loader Start
    let self = this;
    self.preLoaderImage = localStorage.getItem("preloading");
  },
};
</script>
