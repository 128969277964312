module.exports = {
    title: "Discounts",
    crud: {
        create: "Create Discount",
        edit: "Edit Discount",
        view: "View Discount",
    },
    table: {
        code: "Code",
        startDate: "Start Date",
        endDate: "End Date",
        type: 'Type',
        name: 'User Name',
    },
    detail: {
        title: 'City View',
        currencyName: 'currencyName',
        users: 'Discount Users'
    },
    form: {
        code: "Code",
        noOfUsers: "No. of Users",
        type: "Type",
        user: "Staff",
        is_flat: "Is Flat",
        percentage: "Percentage",
        amount: "Amount",
        company_name: 'Partner Agency',
        email: 'Email',
        address: 'Address',
        cp_email: 'Contact Person Email',
        cp_name: 'Contact Person Name',
        contact_no: 'Contact No.',
        cp_contact_no: 'Contact Person Contact No.',
        start_date: 'Start Date',
        end_date: 'End Date',
        new_company: 'New Partner Agency',
    },
    placeholder: {
        country: "Select Country",
        state: "Select State"

    },
    validation: {
        required: {
            type: 'Please select the type.',
            start_date: 'Please select start date.',
            end_date: 'Please select end date.',
            percentage: 'Please enter percentage between 1 to 100.',
            amount: 'Please enter amount.',
            user: 'Please select staff.',
            company_name: 'Please enter partner agency.',
            email: 'Please enter email.',
            address: 'Please enter address.',
            contact_no: 'Please enter contact no.',
            cp_name: 'Please enter contactdiscount person name.',
            cp_email: 'Please enter contact person email.',
            cp_contact_no: 'Please enter contact person contact no.',
        }
    },
}
