module.exports = {
    title: "Feedbacks",
    crud: {
        view: 'View Message',
    },
    table: {
        message: 'Message',
        company: 'User Name',
        added: 'Added At',
        type: 'Type',
        country: 'Country',
        state: 'State',
        city: 'City',
        verified: 'Verified?'
    },
}
