module.exports = {
    title: "Events",
    crud: {
        event_status: "Event Status",
    },
    table: {
        title: "Title",
        isFree: 'isFree?',
        isOnline: 'isOnline?',
        startTime: 'Start Time',
        endTime: 'End Time',
        participants: "Participants",
        createdAt: "Added at",
        updatedAt: "Updated at",
        registeredType: 'Registered As',
        company: 'Company',
        userName: 'User Name',
        comments: {
            name: 'Name',
            comment: 'Comment',
            created: 'Created At',
            updated: 'Updated At',
        },
    },
    detail: {
        title: "title",
        isFree: 'isFree?',
        isOnline: 'isOnline?',
        startTime: 'Start Time',
        endTime: 'End Time',
        participants: "Participants",
        createdAt: "Added at",
        updatedAt: "Updated at",
        link: 'Registration Link',
        location: 'Location',
        description: 'Description'

    },
}
