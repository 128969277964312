module.exports = {
    title: "Departments",
    crud: {
        departments: "Departments",
    },
    table: {
        name: "Department",
        status: 'Is Active',
    },
    detail: {
        name: "Department",
        status: 'Is Active',
    },
}
