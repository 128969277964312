module.exports = {
    title: "Resource Reports",
    crud: {
        create: "Create Reports",
        edit: "Edit Reports",
        view: "View Reports",
    },
    table: {
        reason: "Reason",
        username: 'Reported By',
        isActive: "isActive",
        description: "Description",
    },
    form: {
        reason: "Reason",
        username: 'Reported By',
        isActive: "isActive",
        description: "Description",
    },
    validation: {
        required: {
            reason: 'Reason is required',
        },

    },
}
