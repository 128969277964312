module.exports = {
    title: "Cms Pages",
    crud: {
        create: "Add Page",
        edit: "Edit Page",
    },
    table: {
        page: "Page",
        module: "Module",
        title: "Title",
        description: "Description",
        status: 'Active',
        type: "Type",
    },
    form: {
        page: "Page",
        type: "Type",
        module: "Module",
        title: "Title",
        description: "Description",
        status: 'Status',
    },
    validation: {
        required: {
            page: 'Page is required',
            type: 'Type is required',
            title: 'Title is required',
            description: 'Description is required',
            status: 'Status is required',
            module: 'Please select a module',
        },
    },
}
