import store from '/src/store/store.js';
import {Settings} from '/src/store/settings.js';
import swal from 'sweetalert2';
import moment from "moment";

export const Mixin = {
    created() {
    },
    mounted() {
        this.dismissCountDown = this.dismissSecs;
    },
    data: function () {
        return {
            get dateFormat() {
                return Settings.date.format;
            },
            get userType() {
                return store.state.user.userType;
            },
            get userId() {
                return store.state.user.userId;
            },
            get userTypeId() {
                return store.state.user.userTypeId;
            },
            get dismissSecs() {
                return 5;
            },
            get dismissCountDown() {
                return 0;
            }
        }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        dateTimeUTCtoLocalFormat(val) {
            var stillUtc = moment(String(val)).utc().format('YYYY-MM-DD HH:mm:ss');
            return moment(stillUtc).local().format(Settings.dateTime.format);
        },
        getDateWithFormat(testDate) {
            return moment(testDate).format(Settings.date.format);
        },
        date(testDate) {
            return moment(testDate).format('DD/MM/YYYY');
        },
        statusBudget(str) {
            if (str === 'Yes' || str === 'Active'  || str === 'Selected' || str === 'Approved' || str === 'Verified' || str === 'High' || str === 'Paid' || str === 'Online') {
                return 'success';
            } else if (str === 'Pending' || str === 'Medium') {
                return 'warning';
            } else if (str === 'No' || str === 'Expired' || str === 'InActive' || str === "Blocked" || str === 'Block' || str === 'Non-Verified' || str === 'Rejected' || str === 'Low' || str === 'Failed' || str === 'Offline') {
                return 'danger';
            } else if (str === 'Draft') {
                return 'info';
            } else {
                return 'info';
            }
        },
        handleErrors(errors) {
            let elements = document.getElementsByClassName('text-danger');
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }
            let el;
            let msg;
            let element;
            errors.items.forEach(function (index) {
                element = index.field;
                msg = index.msg;
                el = document.getElementById(element);
                let cls = 'form-group';
                while ((el = el.parentElement) && !el.classList.contains(cls)) ;
                let elChild = document.createElement('span');
                elChild.innerHTML = msg;
                elChild.className = 'text-danger';
                el.appendChild(elChild);
            });
        },
        getCapitalString(str) {
            return str.split(' ').map(this.capitalize).join(' ');
        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        checkPermission(permission) {
            let permissionGlobal = localStorage.getItem('permissions') ? localStorage.getItem('permissions').split(",") : [];
            return (permissionGlobal.indexOf(permission) >= 0);
        },
        getcheckPermission(item) {
            //alert(item.name)
            let new_permission = item.names;
            //  var new_string = new_permission.substring(0, new_permission.indexOf('-'));
            //  alert(new_string);
            //  var list_module = new_permission.replace(new_string,'list');

            if (new_permission === 'profile-verification') {
                // let n = new_permission.indexOf("-");
                // let res = new_permission.substring(n);
                $('.view-profile').prop('checked', true);
                //$('.edit'+res).prop('checked',false);
                //$('.delete'+res).prop('checked',false);
                //$('.view'+res).prop('checked',false);
                //$('.deactive'+res).prop('checked',false);
                //$('.verify'+res).prop('checked',false);
                // $('#persId'+list_module).prop('checked',false)
            }

        },
        createUrlWeb: function (module) {
            return module.web.create;
        },
        statusUrlWeb: function (module, id) {
            return module.web.status.replace("{id}", id);
        },
        editUrlWeb: function (module, id) {
            return module.web.edit.replace("{id}", id);
        },
        deleteUrlWeb: function (module, id) {
            return module.web.delete.replace("{id}", id);
        },
        detailUrlWeb: function (module, id) {
            return module.web.detail.replace("{id}", id);
        },
        listUrlWeb: function (module) {
            return module.web.list;
        },
        createUrlApi: function (module) {
            return module.api.create;
        },
        CountVoteUrlApi: function (module, id) {
            return module.api.voteCount.replace("{id}", id);
        },
        updateUrlweb: function (module, id) {
            return module.web.update.replace("{id}", id);
        },
        profileupdateRequestUrlweb: function (module) {
            return module.web.update;
        },
        editUrlApi: function (module, id) {
            return module.api.update.replace("{id}", id);
        },
        deleteUrlApi: function (module, id) {
            return module.api.delete.replace("{id}", id);
        },
        childUsersUrlApi: function (module, id) {
            return module.api.list.replace("{id}", id);
        },
        listUrlApi: function (module) {
            return module.api.list;
        },
        getRoleUrlApi: function (module) {
            return module.api.getRoles;
        },
        filterUrApi: function (module) {
            return module.api.filter;
        },
        viewUrlApi: function (module, id) {
            return module.api.view.replace("{id}", id);
            // return module.api.view;
        },
        resetUrlApi: function (module, id) {
            return module.api.reset;
        },
        dashboardCountUrlApi: function (module) {
            return module.api.dashboardCount;
        },
        notificationUrlApi: function (module) {
            return module.api.notification;
        },
        notificationAllUrlApi: function (module, page) {
            return module.api.all_notification.replace("{page}", page);
        },
        statusUrlApi: function (module) {
            return module.api.list;
        },
        GetUrlApi: function (module) {
            return module.api.forums;
        },
        ThreadUrlApi: function (module, id) {
            return module.api.thread.replace("{id}", id);
        },
// statusUrlApi: function (module, id) {
        //     return module.api.status.replace("{id}", id);
        // },
        alert(options) {
            swal(options);
        },
        loginUrlApi: function (module) {
            return module.api.login;
        },
        StateUrlApi: function (module) {
            return module.api.state;
        },
        logoutUrlApi: function (module) {
            return module.api.logout;
        },
        eventRegisterUrlWeb: function (module, id) {
            return module.web.registration.replace("{id}", id);
        },
        eventBusinessUrlWeb: function (module, id) {
            return module.web.business.replace("{id}", id);
        },
        companyRegisterUrlApi: function (module) {
            return module.api.company;
        },
        forgotUrlApi: function (module) {
            return module.api.forgot;
        },

        redirect(self, url) {
            self.$router.push(url);
        },

        trimfunction(str, length) {
            let str2 = "...";
            if (str.length > length) {
                let newStr = str.substring(0, length);
                return newStr.concat(str2);
            }
            if (str.length === 0) {
                return '---';
            }
            return str;
        },

        getStringBetween(str, start, end) {
            const result = str.match(new RegExp(start + "(.*)" + end));
            if (result != null) {
                return result[1];
            } else {
                return 0;
            }

        },
        exports(self, type, module, name, id, otherId) {
            if (self != null) {
                const table = self.$refs.myTable;

                if (table.data.length === 0) {
                    // self.$swal(self.$lang.common.label.not_found);
                } else {
                    const url = "/export";
                    const postData = {
                        "param": [table.getRequestParams()],
                        "module": name,
                        "id": id,
                        "group": otherId,
                        "type": type,
                    };

                    if (type === 'Excel') {
                        axios.post(url, postData).then((res) => {
                            self.json_data = res.data.data;
                            self.json_label = Object.assign({}, res.data.column);
                            self.json_title = name;
                        });
                    } else {
                        axios.post(url, postData, {responseType: 'blob'})
                            .then(function (response) {
                                if (response.status === 200) {
                                    let blob = new Blob([response.data], {type: 'application/pdf'})
                                    let link = document.createElement('a')
                                    link.href = window.URL.createObjectURL(blob)
                                    link.download = name + '.pdf'
                                    link.click()
                                }
                            });
                    }
                }
            }
            store.commit('showLoader', false);
        },
        notificationRedirect(code, referenceId) {
            let name = '';
            let redirectId = '';
            switch (code) {
                case 'PROFILE_REQUEST_UPDATE':
                    name = 'Pending Profile Update Request';
                    redirectId = referenceId
                    break;
                case 'PROFILE_UPDATE_REQUEST_TO_ADMIN':
                    name = 'Pending Profile Update Request';
                    redirectId = referenceId
                    break;
                case 'ACCOUNT_VERIFICATION_REQUEST':
                    name = 'Profile View';
                    redirectId = referenceId
                    break;
                case 'CONTACT_REQUEST':
                    name = 'Contact Us';
                    redirectId = referenceId
                    break;
                case 'FEEDBACK_REQUEST':
                    name = 'Feedback';
                    redirectId = referenceId
                    break;
                case 'INQUIRY_REQUEST':
                    name = 'InquiryView';
                    redirectId = referenceId
                    break;
                case 'GROUP_VERIFICATION_REQUEST':
                    name = 'Groups Verification Request View';
                    redirectId = referenceId
                    break;
                case 'PROFILE_CREATED':
                    name = 'Edit Profile';
                    redirectId = referenceId
                    break;
                case 'POST_CREATED':
                    name = 'Post Edit';
                    redirectId = referenceId
                    break;
                case 'PROJECT_CREATED':
                    name = 'Edit Opportunity';
                    redirectId = referenceId
                    break;
                case 'BT_CREATED':
                    name = 'BusinessTraveller Edit';
                    redirectId = referenceId
                    break;


            }
            if (name !== '') {
                if (redirectId === '') {
                    this.$router.push({
                        name: name
                    });
                } else if (redirectId !== '') {
                    this.$router.push({
                        name: name, params: {id: redirectId}
                    });
                }
            }
        },

        removeDuplicateValues(arrOfObj) {
            const dataArr = arrOfObj.map(item => {
                return [item.value, item]
            }); // creates array of array
            const maparr = new Map(dataArr); // create key value pair from array of array
            return [...maparr.values()];//converting back to array from mapobject
        },
        checkUndefinedNull(data) {
            return typeof (data) !== 'undefined' || data !== null ? data : ''
        },
        checkNullValue(object) {
            return object;
        },


    }
}
