module.exports = {
    title: "Report Reason",
    crud: {
        create: "Create Reason",
        edit: "Edit Reason",
        view: "View Reason",
    },
    table: {
        title: "Title",
        type: "Type",
        isActive: 'Active',
    },
    form: {
        title: "Title",
        type: "Type",
        isActive: 'Active',
    },
    validation: {
        required: {
            title: 'Title is required',
            type: 'Type is required',
        },
        minLength: {
            title: 'Title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
