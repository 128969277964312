module.exports = {
    title: "Contact Us List",
    crud: {
        view: 'View Message',
    },
    table: {
        message: 'Message',
        type: 'Type',
        company: 'User Name',
        verified: 'Verified?',
        added: 'Added At'
    },
}
