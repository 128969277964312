module.exports = {
    title: "Profile Update Request",
    pendingTitle: 'Company / Individual Update Request',
    crud: {
        'view_update': 'View Update Request'
    },
    table: {
        name: "Name",
        title: 'Title',
        company: 'Company',
        addedRequest: 'Requested Date',
        status: "Status"
    },
    form: {
        status: "Status",
    },
    validation: {
        required: {
            status: 'Status required'
        }
    },
    meeting: {
        table: {
            date: "Date",
            description: "Description",
            endTime: "End Time",
            meetingLink: "Meeting Link",
            staffName: "Staff Name",
            startTime: "Start Time",
            verifyAt: "Verify At",
            action: "Action",
            reason:'Reason',
        },
    },
}
