module.exports = {
    title: "System Email Template",
    crud: {
        create: "Add Email Template",
        edit: "Edit Email Template",
    },
    table: {
        title: "Title",
        code: "Code",
        subject: "Subject",
        message: "Message",
        status: 'Status',
    },
    form: {
        title: "Title",
        subject: "Subject",
        message: "Message",
        status: 'Status',
    },
    validation: {
        required: {
            subject: 'Subject is required',
            message: 'Message is required',
        },
    },
}
