module.exports = {
    title: "Country",
    crud: {
        create: "Create Country",
        edit: "Edit Country",
        view: "View Country",
    },
    table: {
        name: "Country Name",
        countryCode: "Currency Code",
        countrySymbol: "Currency Symbol",
        isActive: 'Active',
    },
    detail: {
        title: 'Country View',
        currencyName: 'currencyName,'
    },
    form: {
        name: "Country Name",
        isocode: "ISO Code",
        countryCode: "Currency Symbol",
        isActive: 'Active',
    },
    validation: {
        required: {
            name: 'Country name is required',
            isocode: 'ISO Code is required',
            countryCode: 'Currency Symbol is required',
        },
        minLength: {
            name: 'Name must have at least',
            countryCode: 'countryCode must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
