module.exports = {
    job_openings: {
        filter: {
            startDate: 'Start Date',
            endDate: 'End Date',
            industry: 'Industry',
            currency: 'Currency',
            cities:'City',
            workType: 'Work type',
            remoteWork: 'Remote Work',
            skills: 'Recent Skills',
            min_budget: 'Minimum Salary',
            max_budget: 'Maximum Salary',
            required: 'Required',
        },
    },
    resources: {
        filter: {
            startDate: 'Start Date',
            endDate: 'End Date',
            industry: 'Industry',
            country: 'Country',
            cities: 'Cities',
            currency: 'Currency',
            remoteWork: 'Remote Work',
            languages: 'Languages',
            canSpeakWithClient: 'Able to Speak With Client',
            skills: 'Recent Skills',
            otherSkills: 'Other Skills',
            min_per_hours: 'Minimum Price Per Hours',
            max_per_hours: 'Maximum Price Per Hours',
            min_per_months: 'Minimum Price Per Months',
            max_per_months: 'Maximum Price Per Months',
            required: 'Availability',
        },
    },
    candidates: {
        filter: {
            startDate: 'Start Date',
            endDate: 'End Date',
            industry: 'Industry',
            workType: 'Work Type',
            country: 'Country',
            cities: 'Cities',
            currency: 'Currency',
            jobType: 'Job Type',
            commissionType: 'Commission Type',
            skills: 'Recent Skills',
            otherSkills: 'Other Skills',
            min_salary: 'Minimum Salary',
            max_salary: 'Maximum Salary',
            required: 'Availability',
        },
    },
    companies: {
        filter: {
            startDate: 'Start Date',
            endDate: 'End Date',
            industry: 'Industry',
            country: 'Country',
            status :"Status",
            cities:'City',
            size : 'Employee Size',
            currency: 'Currency',
        },
    },
}
