module.exports = {
    title: "Report",
    crud: {
        view: "View Report",
        sent_warning: "Sent Warning",
    },
    table: {
        firstName: "First Name",
        sent_warning: "Sent Warning",
        reason: "Reason",
        company: "Company",
        role: "Role",
        type:"Type",
        name:"Freelancer Name",
    },
    filter: {
        countries: 'Countries',
        states: 'States',
        currency: 'Currencies',
        types: 'Types',
        date: 'Date Range',
        min_budget: "Min Budget",
        max_budget: "Max Budget",
        state:'States',
        industry : 'Industries'

    },
    form: {
        title: "Title",
        showToResource: "Show To Resource",
    },
}
