module.exports = {
    title: "Opportunities",
    crud: {
        create: "Create Opportunity",
        edit: "Edit Opportunity",
        view_response: "View Response",
        view_report: "View Report",
        view_media: 'View Media',
    },
    table: {
        name: "Name",
        description: "Description",
        budget: 'Budget',
        isActive: 'Active',
        remoteWork: 'Remote Work',
    },
    form: {
        name: "Name",
        date: "Date",
        description: "Description",
        OpportunityType: "Opportunity type",
        industry: 'Industry',
        subIndustry: 'Sub Industry',
        resourceType: 'Resource Type',
        currency: 'Currency  Type',
        duration: 'Duration',
        priority: 'Priority',
        budget: 'Budget',
        isActive: 'Active',
        remoteWork: 'Remote Work',
        user: 'User',
        skills: 'Skill',
    },
    validation: {
        required: {
            name: 'Name is required',
            skills: 'Skill is required',
            description: 'Description is required',
            OpportunityType: 'Opportunity type is required',
            industry: 'Industry is required',
            subIndustry: 'Sub Industry is required',
            budget: 'Budget is required',
            resourceType: 'Resource type is required',
            workType: 'Work type is required',
            priority: 'Priority  is required',
            currency: 'Currency type is required',
            duration: 'Duration is required',
            user: 'User is required',
        },
        minLength: {
            name: 'Name must have at least',
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
