module.exports = {
    title: "Notifications",
    load_more: "Load More",
    crud: {
        create: "Create Notification",
        edit: "Edit Notification",
        view: "View Notification",
    },
    table: {
        type: "Type",
        notificationType: "Event",
        title: "Information",
        name: "Name",
        message: "Message",
        isActive: 'Active',
    },
    detail: {
        title: 'Country View',
        currencyName: 'currencyName,'
    },
    form: {
        type: "Type",
        notificationType: "Event (Ex: POST_CREATE)",
        name: "Name",
        title: "Information (notes)",
        message: "Message to user",
        isActive: 'Active',

    },
    validation: {
        required: {
            title: 'Information is required',
            name: 'Name is required',
            message: 'Message is required',
            notificationType: "Event is required",
            type: "Type is required",
        },
        minLength: {
            title: 'Name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
