module.exports = {
    title: "Pricing Types",
    crud: {
        create: "Create Pricing Types",
        edit: "Edit Pricing Types",
        view: "View Pricing Types",
    },
    table: {
        type: "Type",
        isActive: 'Active',
    },
    form: {
        type: "Type",
        isActive: 'Active',

    },
    validation: {
        required: {
            type: 'Type is required',
        },
        minLength: {
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
