module.exports = {
    title: "Price",
    crud: {
        create: "Create Price",
        edit: "Edit Price",
        view: "View Price",
    },
    table: {
        from: "From",
        to: "To",
        title: "Title",
        isActive: 'Active',
    },
    form: {
        from: "From",
        to: "To",
        title: "Title",
        isActive: 'Active',
    },
    validation: {
        required: {
            from: 'From is required',
            to: 'To is required',
            title: 'title is required',
        },
        minLength: {
            title: 'title must have at least',
            from: 'from must have at least',
            to: 'to must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
