module.exports = {
    title: "Staff",
    crud: {
        create: "Create Staff",
        edit: "Edit Staff",
        view: "View Staff",
    },
    table: {
        username: "Username",
        name: "Name",
        email: "Email",
        mobileNumber: "MobileNumber",
        city: "City",
        state: "State",
        country: "Country",
        type: "Type",
        role: "Role",
    },
    form: {
        username: "Username",
        password: 'Password',
        name: "Name",
        email: "Email",
        mobileNumber: "MobileNumber",
        city: "City",
        state: "State",
        country: "Country",
        address1: "Location",
        type: "Type",
        role: "Role",
        timezone: "Timezone",
        startTime: "Start Time",
        endTime: "End Time",
    },
    validation: {
        required: {
            address1: 'Location is required',
            mobileNumber: 'Mobile number is required',
            country: 'Country is required',
            state: 'State is required',
            city: 'City is required',
            username: 'Username is required',
            role: 'Role is required',
            password: 'Password is required',
            email: 'Email is required',
            name: 'Name is required',
            countryName: 'Country name is required',
            stateCode: 'State code is required',
            stateCodeName: 'State code name  is required',
            currencySymbol: 'Currency symbol is required',
            currencyCode: 'Currency code is required',
            currencyName: 'Currency name is required',
            startTime: "Start time is required",
            endTime: "End time is required",
        },
        minLength: {
            name: 'Name must have at least',
            stateCode: 'State code must have at least',
            stateCodeName: 'State code name must have at least',
            currencySymbol: 'Currency symbol must have at least',
            currencyCode: 'Currency code must have at least',
            currencyName: 'Currency name must have at least',
            mobileNumber: 'Mobile number must enter 10 digits.',
        },
        email: {
            email: 'Valid email',
        },
        maxLength: {
            mobileNumber: 'Mobile number must enter 10 digits.',
        },
        numeric: {
            mobileNumber: 'Mobile number must be digit.',
        },
        common: {
            letter: 'letters.',
        }
    },
}
