<template>
  <div id="App">
    <router-view></router-view>
    <pre-loading v-show="this.$store.state.preloader"></pre-loading>
  </div>
</template>

<script>
import PreLoading from "./components/layouts/preloader";

export default {
  name: "App",
  components: {
    PreLoading,
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
