module.exports = {
    title: "Resources",
    crud: {
        create: "Create Resource",
        edit: "Edit Resource",
        view_report: "Report",
        response: "Response",
        detail: "Detail",
    },
    table: {
        firstName: "First Name",
        lastName: "Last Name",
        nickname: 'Nick Name',
        resource: 'Resource',
        company: 'Company',
    },
    form: {
        title: "Title",
        firstname: "First name",
        isRemote: "Can this resource work from client location?",
        lastname: "Last name",
        nickname: "Nick name",
        isActive: "Status",
        resourceType: "Resource type",
        speackWithClient: "Able to speak with client?",
        experience: "Experience",
        availability: "Availability",
        currency: "Currency",
        fromPricePerHours: "Max Price Per Hours",
        toPricePerHours: "Min Price Per Hours",
        fromPricePerMonth: "Max Price Per Month",
        toPricePerMonth: "Min Price Per Month",
        skills: "Skills",
        skillOthers: "Other Skills",
        languages: "Language",
        country: "Country",
        user: "Users",
        state: "State",
        city: "City",
        remoteAddress: "Remote Address",
        remoteCountry: "Remote Country",
        remoteState: "Remote State",
        remoteCity: "Remote City",
    },
    validation: {
        required: {
            user: "User please select user.",
            firstname: "First name is required",
            lastname: "Last name is required",
            nickname: "Nick name is required",
            resourceType: "Resource type is required",
            speackWithClient: "Speak with client is required",
            experience: "Experience is required",
            avaibility: "Availability is required",
            currency: "Currency is required",
            fromPricePerHours: "From Price Per Hours is required",
            toPricePerHours: "To Price Per Hours is required",
            fromPricePerMonth: "From Price Per Month is required",
            toPricePerMonth: "To Price Per Month is required",
            skills: "Skills is required",
            skillOthers: "Other Skills is required",
            languages: "Language is required",
            country: "Country is required",
        },
        common: {
            letter: 'letters.',
        }
    },
}
