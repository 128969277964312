module.exports = {
    title: "Roles",
    crud: {
        create: "Create Role",
        edit: "Edit Role",
    },
    table: {
        username: "Username",
        name: "Role",
        permission: "permission",
        numberOfUsers: 'Number Of Users',
    },
    form: {
        name: "Role",
        permission: "Permission",
    },
    validation: {
        required: {
            name: 'role is required',
        },
        common: {
            letter: 'letters.',
        }
    },
}
