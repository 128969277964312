module.exports = {
    title: "Stream",
    crud: {
        create: "Create Stream",
        edit: "Edit Stream",
    },
    table: {
        name: "Stream Name",
        programmeName: "Programme Name",
        isActive: 'Active',
    },
    form: {
        name: "Stream Name",
        programmeName: "Programme Name",
        isActive: 'Active',
    },
    validation: {
        required: {
            name: 'Stream name is required',
            programmeName: 'Programme name is required',

        },
        minLength: {
            name: 'Name must have at least',
            programmeName: 'Programme name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
