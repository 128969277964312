module.exports = {
    title: "Opportunities",
    crud: {
        create: "Create Opportunity",
        opportunity_resource: "Opportunity Resource",
        edit: "Edit Opportunity",
        view_response: "Response",
        view_report: "Report",
        view_media: 'Media',
        detail: 'Details',
    },
    table: {
        name: "Name",
        opportunityType: "Opportunity Type",
        visibility: "Visibility",
        information: "Information",
        description: "Description",
        budget: 'Budget',
        isActive: 'Active',
        remoteWork: 'Remote Work',
        status: 'Status',
    },
    form: {
        name: "Name",
        opportunityType: "Opportunity Type",
        company: "Company",
        user: 'User',
        information: "Information",
        date: "Date",
        description: "Description",
        OpportunityType: "Opportunity type",
        industry: 'Industry',
        subIndustry: 'Services',
        resourceType: 'Resource Type',
        currency: 'Currency',
        duration: 'Duration',
        priority: 'Priority',
        budget: 'Budget',
        isActive: 'Active',
        remoteWork: 'Remote Work',
        skills: 'Skill',
        tax: 'GST/Tax(%)',
        includedTax: 'GST/Tax included',
        isRemoteWork: 'Remote Work',
        remoteWorkAddress: 'Remote Location',
        status: "Status",
    },

    filter: {
        startDate: 'Start Date',
        endDate: 'End Date',
        industry: 'Industry',
        subIndustry: 'Services',
        resourceType: 'Resource Type',
        currency: 'Currency  Type',
        duration: 'Duration',
        priority: 'Priority',
        budget: 'Budget',
        isActive: 'Active',
        workType: 'Work type',
        user: 'User',
        profileVerified: 'Profile Verified',
        remoteWork: 'Remote Work',
        skills: 'Skills',
        visibility: "Visibility",
        min_budget: 'Minimum Budget',
        max_budget: 'Maximum Budget',
    },

    validation: {
        required: {
            name: 'Name is required',
            skills: 'Skill is required',
            description: 'Description is required',
            OpportunityType: 'opportunity type is required',
            industry: 'Industry is required',
            subIndustry: 'Services is required',
            budget: 'Budget is required',
            resourceType: 'Resource type is required',
            workType: 'Work type is required',
            priority: 'Priority  is required',
            currency: 'Currency type is required',
            duration: 'Duration is required',
            user: 'User is required',
            status: 'Status is required',
        },
        minLength: {
            name: 'Name must have at least',
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
