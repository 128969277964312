module.exports = {
    title: "Business Travellers",

    tab: {
        'details': 'Details',
        'response': 'Responses',
        'report': 'Reports',
    },

    crud: {
        create: "Create Business Travellers",
        edit: "Edit Business Traveller",
        view: "View Business Traveller",
        report: "View Reports",
        response: "View Response",
    },
    table: {
        fromDate: "From Date",
        toDate: "To Date",
        industry: "Industry",
        subIndustry: "Services",
        area: "Area",
        city: "City",
        state: "State",
        country: "Country",
        user: "User",
        isActive: "Active",
        duration: "Duration",
        visiting: "Visiting",
        from: "From",
        status: 'Status',
    },
    form: {
        fromDate: "From Date",
        company: 'Company',
        user: 'User',
        toDate: "To Date",
        industry: "Industry",
        subIndustry: "Services",
        area: "Area",
        city: "Travelling To City",
        state: "Travelling To State",
        country: "Travelling To Country",
        fromCity: "Travelling From City",
        fromState: "Travelling From State",
        fromCountry: "Travelling From Country",

        status: "Status",
        isActive: "Active",
        meetOver: 'When do you want to meet?',
        decisionMaker: 'Can decision makers from other industries approach you?'
    },

    filter: {
        fromDate: "From Date",
        toDate: "To Date",
        industry: "Industry",
        subIndustry: "Services",
        area: "Area",
        city: "City",
        state: "State",
        country: "Country",
        profileVerified: 'Profile Verified',
        travellingCity: "Travelling City",
        travellingState: "Travelling State",
        travellingCountry: "Travelling Country",
        user: "User",
        are: "Area",
    },

    validation: {
        required: {
            title: 'Title is required',
            status: 'Status is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
