module.exports = {
    title: "State",
    crud: {
        create: "Create State",
        edit: "Edit State",
    },
    table: {
        name: "State Name",
        countryName: "Country Name",
        isActive: 'Active',
    },
    form: {
        name: "State Name",
        countryName: "Country Name",
        isActive: 'Active',
    },
    validation: {
        required: {
            name: 'State name is required',
            countryName: 'Country name is required',

        },
        minLength: {
            name: 'Name must have at least',
            countryName: 'Country name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
