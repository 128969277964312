module.exports = {
    title: "Company Service Tax",
    crud: {
        create: "Create Service Tax",
        edit: "Edit Service Tax",
    },
    table: {
        name: "Name",
        countryName: "Country Name",
        isActive: 'Status',
    },
    form: {
        name: "Name",
        countryName: "Country Name",
        isActive: 'Status',
    },
    validation: {
        required: {
            name: 'Name is required',
            countryName: 'Country name is required',

        },
        minLength: {
            name: 'Name must have at least',
            countryName: 'Country name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
