module.exports = {
    title: "User Type",
    crud: {
        create: "Create User Type",
        edit: "Edit User Type",
    },
    table: {
        type: "Type",
    },
    form: {
        type: "Type",
    },
    validation: {
        required: {
            type: 'Type is required',
        },
        minLength: {
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
