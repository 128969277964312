module.exports = {
    title: "Discipline",
    crud: {
        create: "Create Discipline",
        edit: "Edit Discipline",
    },
    table: {
        name: "Discipline Name",
        programmeName: "Programme Name",
        streamName: "Stream Name",
        isActive: 'Active',
    },
    detail: {
        title: 'Discipline View',
        currencyName: 'currencyName,'
    },
    form: {
        name: "Discipline Name",
        programmeName: "Programme Name",
        streamName: "Stream Name",
        isActive: 'Active',
    },
    placeholder: {
        programmeName: "Select Programme Name",
        stream: "Select Stream Name"
    },
    validation: {
        required: {
            programmeName: 'Programme name is required',
            streamName: 'Stream name is required',
            name: 'Discipline name is required',
        },
        minLength: {
            name: 'Name must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
