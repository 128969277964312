let mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    },
    routeChange(state, loader) {
        if (loader === "start") {
            state.preloader = true
        } else if (loader === "end") {
            state.preloader = false
        }
    },
    LOGIN_USER(state) {
        state.user.isLogged = true;
        state.user.preloading = localStorage.getItem('preloading');
        state.user.preLoaderImage = localStorage.getItem('preloader');
        state.user.name = localStorage.getItem('username');
        state.user.permissions = localStorage.getItem('permissions');
        //state.user.userType = localStorage.getItem('user_type');
        //state.user.userTypeId = localStorage.getItem('user_type_id');
        state.user.userId = localStorage.getItem('user_id');
        //state.user.userTypeName = localStorage.getItem('user_type_name');
        state.user.picture = localStorage.getItem('avatar');
        state.token = localStorage.getItem('token');

    },
    showLoader(state, loader) {
        state.preloader = loader;
    },
}

export default mutations
