module.exports = {
    title: "Announcement",
    crud: {
        create: "Create Announcement",
        view: "View Announcement",
    },
    table: {
        type: "Type",
        appType: "App Type",
        message: "Message",
        isMaintenance: "Is Maintenance",
        startDate: "Start Date",
        startTime: "Start Time"
    },
    form: {
        type: "Type",
        appType: "App Type",
        android: "Android",
        is_active: "Is Active",
        ios: "IOS",
        web: "Web",
        new_feature: "New Feature",
        message: "Message",
        isMaintenance: "Is Maintenance",
        startDate: "Start Date",
        startTime: "Start Time",
        endDate: "End Date",
        endTime: "End Time",
    },
    validation: {
        required: {
            type: 'Type is required.',
            appType: 'App type is required.',
            message: "Message is required.",
            startDate: "Start Date is required.",
            startTime: "Start Time is required.",
            endDate: "End Date is required.",
            endTime: "End Time is required.",
        }
    },
}
