module.exports = {
    title: "Version",
    crud: {
        create: "Create Version",
        view: "View Version",
    },
    table: {
        appType: "Os Type",
        forceUpdate: "Force Update",
        status: "Status",
        versionCode: "Version Code",
    },
    form: {
        type: "Type",
        appType: "App Type",
        android: "Android",
        is_active: "Is Active",
        ios: "IOS",
        web: "Web",
        force_update: "Force Update",
        version: "Version",
        isMaintenance: "Is Maintenance",
        startDate: "Start Date",
        startTime: "Start Time",
        endDate: "End Date",
        endTime: "End Time",
    },
    validation: {
        required: {
            version: 'Version is required.',
            appType: 'App Type is required.',
        }
    },
}
