module.exports = {
    title: "Platforms",
    crud: {
        create: "Create",
        edit: "Edit",
        view: "View",
    },
    table: {
        platform: "Platform Name",
        logo: "Logo",
        isActive: 'Active',
    },
    detail: {
        platform: 'Country Platform',
    },
    form: {
        platform: "Country Platform",
        logo: "Logo",
        isActive: 'Active',
    },
    validation: {
        required: {
            platform: 'Country Platform',
        },
        minLength: {
            platform: 'platform must have at least',
        },
    },
}
