module.exports = {
    title: "Skills",
    crud: {
        create: "Create Skill",
        edit: "Edit Skill",
        view: "View Skill",
        merge: "Merge Skill",
        other: "Other Skill",
    },
    table: {
        title: "Skills",
        industry: "Industry",
        isDefault: "Is Default",
    },
    form: {
        title: "Title",
        newtitle: "New title",
        industry: "Industry",
        duplicate: "Move To Skill",
    },
    validation: {
        required: {
            title: 'Title is required',
            industryId: 'Industry is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
