export const Settings = {
    date: {
        format: "DD-MM-YYYY"
    },
    time: {
        format: "dd/MM/yyyy"
    },
    justTime: {
        format: "hh:mm A"
    },
    dateTime : {
        format : 'DD-MM-YYYY hh:mm A'
    }
};