module.exports = {
    title: "Recruitment Response List",
    crud: {
        create: "Create Response",
        edit: "Edit Response",
        view: "View Response",
    },
    table: {
        response: "Response",
        priority: "Priority",
        username: "Response By",
        name: "Name",
        description: "Description",
        traveller: "Traveller",
        user: "Traveller",
        sender: 'Sender',
        addedAt: "Added At"
    },
    form: {
        priority: "Priority",
        user: "Response By",
        sender: 'Sender',
        traveller: "Traveller",
        response: "Response Message",
        isActive: "isActive",
        description: "Description",
    },
    validation: {
        required: {
            reason: 'Reason is required',
        },

    },
}
