module.exports = {
    title: "E-Experience",

    tab: {
        details: 'Details',
        media: 'Attachments',
        report: 'Report',
    },
    crud: {
        create: "Create",
        edit: "Edit",
        view_response: "View Response",
        view_report: "View Report",
        view_media: 'View Attachments',
        e_status: "Status"
    },
    table: {
        industry: "Industry",
        job_title: "Job title",
        firstName: "First Name",
        lastName: "Last Name",
        empId: "Employee Id",
        companyName: "Company",
        panNumber: "Pan Number",
        aadhaarNumber: "Aadhaar Number",
        updatedAt: "Updated At"
    },
    detail: {
        companyName: "Company",
        companyWebsite: "Company Website",
        industry: "Industry",
        firstName: "First Name",
        lastName: "Last Name",
        empId: "Employee Id",
        jobTitle: "Job Title",
        lastDateOfWorking: "Last Date Of Working",
        panNumber: "Pan number",
        aadhaarNumber: "Aadhaar Number",
        reason: "Reason",
        city: "City",
        country: "Country",
        user: "Created By",
        company_website: "Company Website",
        remarks: "Remarks",
        description: 'Description',
        reasons: "Reasons",
        createdAt: "Created At",
    },
    report: {
        crud: {
            edit: 'Edit',
            view: 'View'
        },
        table: {
            reason: "Reason",
            username: "Username",
            description: "Description",
        }
    },
    filter: {},
    validation: {
        required: {},
        minLength: {},
        common: {}
    },
}
