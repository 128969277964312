module.exports = {
    title: "Student Result",
    crud: {
        view: "View Student Result",
    },
    table: {
        isVerify: "Is Verify",
        marks: "Mark",
        semester: "Semester",
    },
    form: {
        year: "Year",
        seats: "Seats",
        discipline: "Discipline",
        programme: "Programme",
        stream: "Stream",
        noOfSemester: "No Of Semester",
    }
}