module.exports = {
    title: "Rules",
    crud: {
        create: "Add Rule",
        edit: "Edit Rule",
    },
    table: {
        title: "Title",
        description: "Description",
        status: 'Status',
    },
    form: {
        title: "Title",
        description: "Description",
        status: 'Status',
        isForceFullyUpdated:'Forcefully updated.'
    },
    validation: {
        required: {
            title: 'Title is required',
            description: 'Description is required',
            status: 'Status is required',
        },
    },
}
