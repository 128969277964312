module.exports = {
    title: "Availability",
    crud: {
        create: "Create Availability",
        edit: "Edit Availability",
        view: "View Availability",
    },
    table: {
        title: "Title",
        isRestricted: 'Restricted("Yes" not show in post)',
    },
    form: {
        title: "Title",
        showToResource: "Show To Resource",
    },
    validation: {
        required: {
            title: 'Title is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
