module.exports = {
    title: "Language",
    crud: {
        create: "Create Language",
        edit: "Edit Language",
    },
    table: {
        title: "Name",
        code: "Code",
    },
    form: {
        code: "Code",
        title: "Name",
    },
    validation: {
        required: {
            title: 'Name is required',
            code: 'Code is required',
        },
        minLength: {
            title: 'Name must have at least',
            code: 'State code must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
