// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['556 134',`
  <g id="Group_11" data-name="Group 11" clip-path="url(#clip-path)">
    <path id="Path_16" data-name="Path 16" d="M0,95.835V8.458H39.694a32.316,32.316,0,0,1,15.416,3.62A27.78,27.78,0,0,1,65.845,22a26.45,26.45,0,0,1,3.932,14.291A28.362,28.362,0,0,1,53.924,62.382L73.4,95.835H47.808L30.706,65.5H21.345V95.835ZM21.345,48.651H37.322a9.809,9.809,0,0,0,7.614-3,11.363,11.363,0,0,0,2.746-7.989,10.207,10.207,0,0,0-3.121-7.8q-3.121-2.931-8.738-2.933H21.345Z" transform="translate(0 -3.091)" fill="#045de8"/>
    <path id="Path_17" data-name="Path 17" d="M157.419,108.367a39.871,39.871,0,0,1-19.285-4.431,31.852,31.852,0,0,1-12.669-12.171,34.4,34.4,0,0,1-4.493-17.6,36.28,36.28,0,0,1,4.368-18.037,32.393,32.393,0,0,1,11.983-12.3,33.484,33.484,0,0,1,17.351-4.494,31.5,31.5,0,0,1,16.6,4.369,30.013,30.013,0,0,1,11.234,11.983,35.807,35.807,0,0,1,3.87,17.35l-.125,5.367H141.442a15.186,15.186,0,0,0,6.3,8.488q4.68,3.122,12.046,3.12a20.8,20.8,0,0,0,7.3-1.185,25.635,25.635,0,0,0,6.8-4.056l9.861,13.98a40.326,40.326,0,0,1-26.338,9.611m-2.371-51.552q-11.361,0-13.856,10.236h25.714v-.125a10,10,0,0,0-3.557-7.24,12.222,12.222,0,0,0-8.3-2.871" transform="translate(-44.205 -14.375)" fill="#045de8"/>
    <path id="Path_18" data-name="Path 18" d="M259.132,108.367a45.677,45.677,0,0,1-17.537-3.12,31.185,31.185,0,0,1-12.046-8.364L242.281,85.9a23.336,23.336,0,0,0,8.8,5.617,27.543,27.543,0,0,0,9.175,1.748,8.2,8.2,0,0,0,4.992-1.31A4.094,4.094,0,0,0,267,88.52a3.974,3.974,0,0,0-2.123-3.62q-2.246-1.246-10.485-3.37Q242.9,78.66,237.412,73.292a16.865,16.865,0,0,1-3.994-5.8,18.842,18.842,0,0,1-1.373-7.3,17.15,17.15,0,0,1,3.745-11.047,24.338,24.338,0,0,1,9.924-7.239,34.082,34.082,0,0,1,13.169-2.559,44.051,44.051,0,0,1,15.54,2.684,39.542,39.542,0,0,1,12.419,7.3L275.857,61.684a32.014,32.014,0,0,0-7.926-5.243,20.869,20.869,0,0,0-9.05-2.246q-6.492,0-6.491,4.743a4.579,4.579,0,0,0,2.621,4.119,26.258,26.258,0,0,0,3.87,1.56q2.744.936,7.614,2.309a59.944,59.944,0,0,1,10.111,3.683,21.945,21.945,0,0,1,6.741,4.806,15.329,15.329,0,0,1,3.994,10.735,18.843,18.843,0,0,1-3.682,11.484A24.878,24.878,0,0,1,273.61,105.5a34.883,34.883,0,0,1-14.479,2.87" transform="translate(-83.88 -14.375)" fill="#045de8"/>
    <path id="Path_19" data-name="Path 19" d="M480.468,109.282q-10.986,0-17.163-7.053t-6.179-19.784V41.5h21.22V79.949q0,5.617,2.684,8.738t7.677,3.121a11.063,11.063,0,0,0,7.989-3,10.1,10.1,0,0,0,3.121-7.614V41.5h21.219v66.406H501.188l-.624-8.238a23.177,23.177,0,0,1-8.613,6.99,25.829,25.829,0,0,1-11.484,2.621" transform="translate(-167.041 -15.166)" fill="#045de8"/>
    <path id="Path_20" data-name="Path 20" d="M583.014,107.118V40.712h19.972l.374,9.861a27.021,27.021,0,0,1,8.55-8.114,21.059,21.059,0,0,1,11.047-3.121,22.34,22.34,0,0,1,4.119.375,33.7,33.7,0,0,1,3.62.874l-5.742,23.341a19.283,19.283,0,0,0-3.745-1.435,18.4,18.4,0,0,0-5.118-.687,11.248,11.248,0,0,0-8.55,3.495,12.716,12.716,0,0,0-3.308,9.113v32.7Z" transform="translate(-213.042 -14.375)" fill="#045de8"/>
    <path id="Path_21" data-name="Path 21" d="M693.412,108.367a34.065,34.065,0,0,1-17.538-4.493,32.705,32.705,0,0,1-12.108-12.3,35.114,35.114,0,0,1-4.431-17.662,35.372,35.372,0,0,1,4.431-17.787,32.71,32.71,0,0,1,12.108-12.3,34.065,34.065,0,0,1,17.538-4.494A42.861,42.861,0,0,1,709.764,42.4a28.584,28.584,0,0,1,11.858,8.551l-11.484,13.73a20.478,20.478,0,0,0-6.3-4.993,18.142,18.142,0,0,0-8.55-2A14.329,14.329,0,0,0,684.3,62.245a16.275,16.275,0,0,0-4.244,11.547A16.55,16.55,0,0,0,684.3,85.337a14.14,14.14,0,0,0,10.984,4.681,18.852,18.852,0,0,0,8.426-1.81,19.772,19.772,0,0,0,6.428-5.055L721.5,97.009a29.6,29.6,0,0,1-11.92,8.3,42.67,42.67,0,0,1-16.165,3.058" transform="translate(-240.931 -14.375)" fill="#045de8"/>
    <path id="Path_22" data-name="Path 22" d="M800.427,108.367a39.872,39.872,0,0,1-19.285-4.431,31.852,31.852,0,0,1-12.669-12.171,34.4,34.4,0,0,1-4.494-17.6,36.28,36.28,0,0,1,4.369-18.037,32.381,32.381,0,0,1,11.982-12.3,33.485,33.485,0,0,1,17.351-4.494,31.5,31.5,0,0,1,16.6,4.369,30,30,0,0,1,11.234,11.983,35.785,35.785,0,0,1,3.87,17.35l-.124,5.367H784.45a15.186,15.186,0,0,0,6.3,8.488q4.68,3.122,12.046,3.12a20.8,20.8,0,0,0,7.3-1.185,25.624,25.624,0,0,0,6.8-4.056l9.861,13.98a40.326,40.326,0,0,1-26.338,9.611m-2.371-51.552q-11.361,0-13.855,10.236h25.714v-.125a9.994,9.994,0,0,0-3.558-7.24,12.219,12.219,0,0,0-8.3-2.871" transform="translate(-279.169 -14.375)" fill="#045de8"/>
    <path id="Path_23" data-name="Path 23" d="M884.01,92.743V45.56h-9.486V28.335h9.486V23.716a24.985,24.985,0,0,1,2.871-11.983,22.1,22.1,0,0,1,8.051-8.55A22.53,22.53,0,0,1,906.978,0a26.78,26.78,0,0,1,8.862,1.435A28.1,28.1,0,0,1,923.2,5.242L917.338,20.6a14.461,14.461,0,0,0-5.742-1.623q-6.366,0-6.366,6.741v2.621h13.356V45.56H905.23V92.743Z" transform="translate(-319.564 0)" fill="#045de8"/>
    <path id="Path_24" data-name="Path 24" d="M980.084,109.282q-10.986,0-17.164-7.053t-6.178-19.784V41.5h21.219V79.949q0,5.617,2.684,8.738t7.676,3.121a11.063,11.063,0,0,0,7.989-3,10.1,10.1,0,0,0,3.121-7.614V41.5h21.22v66.406H1000.8l-.624-8.238a23.173,23.173,0,0,1-8.613,6.99,25.828,25.828,0,0,1-11.483,2.621" transform="translate(-349.608 -15.166)" fill="#045de8"/>
    <rect id="Rectangle_3" data-name="Rectangle 3" width="21.219" height="92.369" transform="translate(687.021 0.375)" fill="#045de8"/>
    <path id="Path_25" data-name="Path 25" d="M366.421,33.571a37.911,37.911,0,0,0-37.915,37.915,37.914,37.914,0,1,0,37.915-37.915M336,71.486A30.421,30.421,0,0,1,366.421,41.06c.309,0,.616.014.923.023a84.668,84.668,0,0,1,.561,17.644,12.666,12.666,0,0,0-9.99,22.108,61.092,61.092,0,0,0-.207,8.59c.169,4.342.646,8.513,1.061,11.51A30.479,30.479,0,0,1,336,71.486m30.038,5.81c.074,0,.142.022.217.022a6.026,6.026,0,1,0-6.027-6.027,5.92,5.92,0,0,0,.079.777,15.466,15.466,0,0,0-.969,2.18,7.522,7.522,0,1,1,6.917,4.566c-.189,0-.374-.015-.56-.029.1-.539.213-1.049.343-1.49m-1.233-6a1.449,1.449,0,1,1,1.45,1.449,1.45,1.45,0,0,1-1.45-1.449m1.675,30.619a94.426,94.426,0,0,1-1.307-17.99c.358.03.716.055,1.083.055a12.675,12.675,0,0,0,8.8-21.809c1.089-6.848.561-14.795,0-19.852a30.422,30.422,0,0,1-8.569,59.6" transform="translate(-120.041 -12.267)" fill="#313134"/>
  </g>
`] , logo2 = ['110 134',`
  <g id="Group_11" data-name="Group 11" clip-path="url(#clip-path)">
    <path id="Path_16" data-name="Path 16" d="M0,95.835V8.458H39.694a32.316,32.316,0,0,1,15.416,3.62A27.78,27.78,0,0,1,65.845,22a26.45,26.45,0,0,1,3.932,14.291A28.362,28.362,0,0,1,53.924,62.382L73.4,95.835H47.808L30.706,65.5H21.345V95.835ZM21.345,48.651H37.322a9.809,9.809,0,0,0,7.614-3,11.363,11.363,0,0,0,2.746-7.989,10.207,10.207,0,0,0-3.121-7.8q-3.121-2.931-8.738-2.933H21.345Z" transform="translate(0 -3.091)" fill="#045de8"/>
    <path id="Path_17" data-name="Path 17" d="M157.419,108.367a39.871,39.871,0,0,1-19.285-4.431,31.852,31.852,0,0,1-12.669-12.171,34.4,34.4,0,0,1-4.493-17.6,36.28,36.28,0,0,1,4.368-18.037,32.393,32.393,0,0,1,11.983-12.3,33.484,33.484,0,0,1,17.351-4.494,31.5,31.5,0,0,1,16.6,4.369,30.013,30.013,0,0,1,11.234,11.983,35.807,35.807,0,0,1,3.87,17.35l-.125,5.367H141.442a15.186,15.186,0,0,0,6.3,8.488q4.68,3.122,12.046,3.12a20.8,20.8,0,0,0,7.3-1.185,25.635,25.635,0,0,0,6.8-4.056l9.861,13.98a40.326,40.326,0,0,1-26.338,9.611m-2.371-51.552q-11.361,0-13.856,10.236h25.714v-.125a10,10,0,0,0-3.557-7.24,12.222,12.222,0,0,0-8.3-2.871" transform="translate(-44.205 -14.375)" fill="#045de8"/>
    <path id="Path_18" data-name="Path 18" d="M259.132,108.367a45.677,45.677,0,0,1-17.537-3.12,31.185,31.185,0,0,1-12.046-8.364L242.281,85.9a23.336,23.336,0,0,0,8.8,5.617,27.543,27.543,0,0,0,9.175,1.748,8.2,8.2,0,0,0,4.992-1.31A4.094,4.094,0,0,0,267,88.52a3.974,3.974,0,0,0-2.123-3.62q-2.246-1.246-10.485-3.37Q242.9,78.66,237.412,73.292a16.865,16.865,0,0,1-3.994-5.8,18.842,18.842,0,0,1-1.373-7.3,17.15,17.15,0,0,1,3.745-11.047,24.338,24.338,0,0,1,9.924-7.239,34.082,34.082,0,0,1,13.169-2.559,44.051,44.051,0,0,1,15.54,2.684,39.542,39.542,0,0,1,12.419,7.3L275.857,61.684a32.014,32.014,0,0,0-7.926-5.243,20.869,20.869,0,0,0-9.05-2.246q-6.492,0-6.491,4.743a4.579,4.579,0,0,0,2.621,4.119,26.258,26.258,0,0,0,3.87,1.56q2.744.936,7.614,2.309a59.944,59.944,0,0,1,10.111,3.683,21.945,21.945,0,0,1,6.741,4.806,15.329,15.329,0,0,1,3.994,10.735,18.843,18.843,0,0,1-3.682,11.484A24.878,24.878,0,0,1,273.61,105.5a34.883,34.883,0,0,1-14.479,2.87" transform="translate(-83.88 -14.375)" fill="#045de8"/>
    <path id="Path_19" data-name="Path 19" d="M480.468,109.282q-10.986,0-17.163-7.053t-6.179-19.784V41.5h21.22V79.949q0,5.617,2.684,8.738t7.677,3.121a11.063,11.063,0,0,0,7.989-3,10.1,10.1,0,0,0,3.121-7.614V41.5h21.219v66.406H501.188l-.624-8.238a23.177,23.177,0,0,1-8.613,6.99,25.829,25.829,0,0,1-11.484,2.621" transform="translate(-167.041 -15.166)" fill="#045de8"/>
    <path id="Path_20" data-name="Path 20" d="M583.014,107.118V40.712h19.972l.374,9.861a27.021,27.021,0,0,1,8.55-8.114,21.059,21.059,0,0,1,11.047-3.121,22.34,22.34,0,0,1,4.119.375,33.7,33.7,0,0,1,3.62.874l-5.742,23.341a19.283,19.283,0,0,0-3.745-1.435,18.4,18.4,0,0,0-5.118-.687,11.248,11.248,0,0,0-8.55,3.495,12.716,12.716,0,0,0-3.308,9.113v32.7Z" transform="translate(-213.042 -14.375)" fill="#045de8"/>
    <path id="Path_21" data-name="Path 21" d="M693.412,108.367a34.065,34.065,0,0,1-17.538-4.493,32.705,32.705,0,0,1-12.108-12.3,35.114,35.114,0,0,1-4.431-17.662,35.372,35.372,0,0,1,4.431-17.787,32.71,32.71,0,0,1,12.108-12.3,34.065,34.065,0,0,1,17.538-4.494A42.861,42.861,0,0,1,709.764,42.4a28.584,28.584,0,0,1,11.858,8.551l-11.484,13.73a20.478,20.478,0,0,0-6.3-4.993,18.142,18.142,0,0,0-8.55-2A14.329,14.329,0,0,0,684.3,62.245a16.275,16.275,0,0,0-4.244,11.547A16.55,16.55,0,0,0,684.3,85.337a14.14,14.14,0,0,0,10.984,4.681,18.852,18.852,0,0,0,8.426-1.81,19.772,19.772,0,0,0,6.428-5.055L721.5,97.009a29.6,29.6,0,0,1-11.92,8.3,42.67,42.67,0,0,1-16.165,3.058" transform="translate(-240.931 -14.375)" fill="#045de8"/>
    <path id="Path_22" data-name="Path 22" d="M800.427,108.367a39.872,39.872,0,0,1-19.285-4.431,31.852,31.852,0,0,1-12.669-12.171,34.4,34.4,0,0,1-4.494-17.6,36.28,36.28,0,0,1,4.369-18.037,32.381,32.381,0,0,1,11.982-12.3,33.485,33.485,0,0,1,17.351-4.494,31.5,31.5,0,0,1,16.6,4.369,30,30,0,0,1,11.234,11.983,35.785,35.785,0,0,1,3.87,17.35l-.124,5.367H784.45a15.186,15.186,0,0,0,6.3,8.488q4.68,3.122,12.046,3.12a20.8,20.8,0,0,0,7.3-1.185,25.624,25.624,0,0,0,6.8-4.056l9.861,13.98a40.326,40.326,0,0,1-26.338,9.611m-2.371-51.552q-11.361,0-13.855,10.236h25.714v-.125a9.994,9.994,0,0,0-3.558-7.24,12.219,12.219,0,0,0-8.3-2.871" transform="translate(-279.169 -14.375)" fill="#045de8"/>
    <path id="Path_23" data-name="Path 23" d="M884.01,92.743V45.56h-9.486V28.335h9.486V23.716a24.985,24.985,0,0,1,2.871-11.983,22.1,22.1,0,0,1,8.051-8.55A22.53,22.53,0,0,1,906.978,0a26.78,26.78,0,0,1,8.862,1.435A28.1,28.1,0,0,1,923.2,5.242L917.338,20.6a14.461,14.461,0,0,0-5.742-1.623q-6.366,0-6.366,6.741v2.621h13.356V45.56H905.23V92.743Z" transform="translate(-319.564 0)" fill="#045de8"/>
    <path id="Path_24" data-name="Path 24" d="M980.084,109.282q-10.986,0-17.164-7.053t-6.178-19.784V41.5h21.219V79.949q0,5.617,2.684,8.738t7.676,3.121a11.063,11.063,0,0,0,7.989-3,10.1,10.1,0,0,0,3.121-7.614V41.5h21.22v66.406H1000.8l-.624-8.238a23.173,23.173,0,0,1-8.613,6.99,25.828,25.828,0,0,1-11.483,2.621" transform="translate(-349.608 -15.166)" fill="#045de8"/>
    <rect id="Rectangle_3" data-name="Rectangle 3" width="21.219" height="92.369" transform="translate(687.021 0.375)" fill="#045de8"/>
    <path id="Path_25" data-name="Path 25" d="M366.421,33.571a37.911,37.911,0,0,0-37.915,37.915,37.914,37.914,0,1,0,37.915-37.915M336,71.486A30.421,30.421,0,0,1,366.421,41.06c.309,0,.616.014.923.023a84.668,84.668,0,0,1,.561,17.644,12.666,12.666,0,0,0-9.99,22.108,61.092,61.092,0,0,0-.207,8.59c.169,4.342.646,8.513,1.061,11.51A30.479,30.479,0,0,1,336,71.486m30.038,5.81c.074,0,.142.022.217.022a6.026,6.026,0,1,0-6.027-6.027,5.92,5.92,0,0,0,.079.777,15.466,15.466,0,0,0-.969,2.18,7.522,7.522,0,1,1,6.917,4.566c-.189,0-.374-.015-.56-.029.1-.539.213-1.049.343-1.49m-1.233-6a1.449,1.449,0,1,1,1.45,1.449,1.45,1.45,0,0,1-1.45-1.449m1.675,30.619a94.426,94.426,0,0,1-1.307-17.99c.358.03.716.055,1.083.055a12.675,12.675,0,0,0,8.8-21.809c1.089-6.848.561-14.795,0-19.852a30.422,30.422,0,0,1-8.569,59.6" transform="translate(-120.041 -12.267)" fill="#313134"/>
  </g>
`]
