import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import { createFlashStore } from 'vuex-flash';

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        sidebarShow: 'responsive',
        sidebarMinimize: false,
        left_open: true,
        preloader: false,
        site_name: "Byzlink",
        page_title: null,
        gmap_key: 'AIzaSyDq6UI1FsVcF8bNrB1yJuZwdeBmEnVcbRM',
        defaultImage: 'src/assets/img/defaultImage.jpg',


        defaultUploadImage: {
            doc: localStorage.getItem('defaultDocImage'),
            pdf: localStorage.getItem('defaultPdfImage'),
            xls: localStorage.getItem('defaultXlsImage'),
        },
        loader: false,

        user: {
            preloading: localStorage.getItem('preloading'),
            name: localStorage.getItem('username') ? localStorage.getItem('username') : "Bizlynk",
            picture: (localStorage.getItem('avatar') === "") ? "src/assets/img/avatar1.jpg" : localStorage.getItem('avatar'),
            isLogged: (!(localStorage.getItem('token') === "" || localStorage.getItem('token') == null)),
            permissions: localStorage.getItem('permissions') ? localStorage.getItem('permissions').split(",") : [],
            userType: localStorage.getItem('userType') ? localStorage.getItem('userType') : "",
            userId: localStorage.getItem('id') ? localStorage.getItem('id') : "",
        },
        dateFormat: 'DD/MM/YYYY',
        dateTimeFormat: 'DD/MM/YYYY hh:mm:ss A',
        dateFormat2: 'YYYY-MM-DD hh:mm:ss A',
        timeFormat: 'hh:mm:ss A',
        token: localStorage.getItem('token') ? localStorage.getItem('token') : ""
    },
    plugins: [
        createFlashStore()
    ],
    mutations
})
export default store
