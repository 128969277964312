<template>
  <CButton
      color="info"
      @click="sendToShow(show)"
      v-on:click="show = !show"
      v-c-tooltip="$lang.buttons.general.crud.filter"
      class="ml-2"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-funnel"
         viewBox="0 0 16 16">
      <path
          d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
    </svg>
  </CButton>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import "cropperjs/dist/cropper.css";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "BeforeFilter",
  mixins: [Mixin],
  data() {
    return {
      show: false,
    };
  },
  props: ["filterShow"],
  mounted() {
  },
  methods: {
    sendToShow: function (show) {
      self.filterShow = !show;
      self.show = self.filterShow;
      this.$emit("getFilterToggle", self.show);
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
