module.exports = {
    title: "Industry",
    crud: {
        create: "Create Industry",
        edit: "Edit Industry",
        view: "View Industry",
    },
    table: {
        industry: "Industry",
        code: "Code",
        industryType: "Industry Type",
        subIndustry: "Service",
        isActive: "Is Active",
    },
    form: {
        code: "Code",
        title: "Title",
        industry: "Industry",
        industryType: "Industry Type",
        subIndustry: "Service",
        isActive: 'Active'
    },
    validation: {
        required: {
            title: 'title is required',
            code: 'code is required',
            industryType: 'Industry Type is required',
        },
        minLength: {
            title: 'Title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
