module.exports = {
    title: "Change Password",
    crud: {
        create: "Create Opportunity",
        edit: "Edit Opportunity",
        view_response: "View Response",
        view_report: "View Report",
        view_media: 'View Media',
    },
    table: {
        name: "Name",
        description: "Description",
        budget: 'Budget',
        isActive: 'Active',
        remoteWork: 'Remote Work',
    },
    form: {
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
    },
    validation: {
        required: {
            oldPassword: 'Old password is required.',
            newPassword: 'New password is required.',
            confirmPassword: 'Confirm password is required.',
        },
        sameAsPassword: {
            confirmPassword: 'Confirm password does not match with new password.',
        },
        minLength: {
            newPassword: 'Password must have at least  6 letters.',
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
