module.exports = {
    title: "Student",
    crud: {
        view: "View",
        detail: "Detail",
        skill: "Skill",
        project: "Project",
        certificate: "Certificate",
        award: "Award",
        location: "Location",
        result: "Result",
    },
    filter:{
        college : "College",
    },
    label:{
        no_content : "No Content",
    },
    table: {
        firstName: "First Name",
        nickname: "Nick Name",
        description: "Description",
        enrollmentNo: "Enrollment No",
        enrollmentYear: "Enrollment Year",
        passingYear: "Passing Year",
    },
    form: {
        firstName: "First Name",
        nickname: "Nick Name",
        description: "Description",
        enrollmentNo: "Enrollment No",
        enrollmentYear: "Enrollment Year",
        passingYear: "Passing Year",
        countryCode: "Country Code",
        email: "Email",
        interestArea: "Interest Area",
        internType: "Intern Type",
        lastName: "Last Name",
        phoneNumber: "Phone Number",
        collegeProgramme: "College Programme",
        collegeStream: "College Stream",
        collegeDiscipline: "College Discipline",
        college: "College",
        logo: "Logo",
        no_content: "No Content",
    }
}